import * as React from 'react'
import { StyledPopover } from './styles'
import { oc } from 'ts-optchain'
import { Portal } from 'react-portal'

type Props = {
  isExternal?: boolean
  flexibleWidth?: boolean
  bindToRef: any
  containerStyles?: any
  children?: any
}

export const WindowPopover = (props: Props) => {
  const { isExternal, flexibleWidth, containerStyles, bindToRef, children } = props
  const popoverRef = React.useRef(null)

  React.useEffect(() => {
    const fn = () => updatePopoverPosition(bindToRef, popoverRef, flexibleWidth)
    window.addEventListener('resize', fn)
    window.addEventListener('scroll', fn, true)

    return () => {
      window.removeEventListener('resize', fn)
      window.removeEventListener('scroll', fn, true)
    }
  }, [])

  React.useEffect(() => {
    updatePopoverPosition(bindToRef, popoverRef, flexibleWidth)
  })

  return isExternal ? (
    <Portal>
      <StyledPopover ref={popoverRef} style={containerStyles}>
        {children}
      </StyledPopover>
    </Portal>
  ) : (
    <StyledPopover ref={popoverRef} style={containerStyles}>
      {children}
    </StyledPopover>
  )
}

const updatePopoverPosition = (itemToBindRef: any, popoverRef: any, flexibleWidth: boolean) => {
  const itemToBind = oc(itemToBindRef).current(null)
  const popover = oc(popoverRef).current(null)

  if (popover && itemToBind) {
    const itemToBindProps = itemToBind.getBoundingClientRect()
    let changePosition = false
    let positionLeft = itemToBindProps.left
    let positionTop = itemToBindProps.top + itemToBindProps.height

    popover.style.left = positionLeft + 'px'
    popover.style.top = positionTop + 'px'
    popover.style[flexibleWidth ? 'minWidth' : 'width'] = itemToBindProps.width + 'px'

    const dropdownProps = popover.getBoundingClientRect()

    if (dropdownProps.bottom > window.innerHeight) {
      const enoughtTopPlace = dropdownProps.height < dropdownProps.top

      if (enoughtTopPlace) {
        changePosition = true
        positionTop = itemToBindProps.top - dropdownProps.height
      }
    }
    if (dropdownProps.right > window.innerWidth) {
      changePosition = true
      positionLeft = itemToBindProps.right - dropdownProps.width
    }

    if (dropdownProps.left < 0) {
      changePosition = true
      positionLeft = dropdownProps.width * 0.25
    }

    if (changePosition) {
      popover.style.left = positionLeft + 'px'
      popover.style.top = positionTop + 'px'
    }

    popover.style.visibility = 'visible'
  }
}
