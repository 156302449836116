import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { createDriverReport, requestDriverReports } from '../components/common/drivers/epics'
import { DriverReportDTO } from '../api/origin/document-service'
import { IReports, VendorAccountingReportsContext } from '../contexts/VendorAccountingReportsContext'
import { createId } from '../services/utils'
import { dateService } from '../services/timeService'
import { DriverViewDTO } from '../api/api'
import { useExpandedItem } from '../hooks/useExpandedItem'

type OwnProps = {
  children: any
}

type StateProps = {}

type Props = OwnProps & StateProps

export const VendorAccountingReportsProvider = React.memo((props: Props) => {
  const { children } = props
  const { parentItem, data, isModified, setFetching, mergeDataProps } = useExpandedItem()
  const driver: DriverViewDTO = parentItem
  const vendorAccountingReportsRef = React.useRef(oc(data).vendorAccountingReports({}))
  const [fetchError, setFetchError] = React.useState(false)
  vendorAccountingReportsRef.current = oc(data).vendorAccountingReports()
  const isModifiedMode = isModified

  const deleteReportId = React.useCallback((reportType: DriverReportDTO.TypeEnum, reportId: string) => {
    const updatedVendorAccountingReports = { ...(vendorAccountingReportsRef.current || {}) }

    updatedVendorAccountingReports[reportType] = R.omit(updatedVendorAccountingReports[reportType] || {}, [reportId])
    mergeDataProps({ vendorAccountingReports: updatedVendorAccountingReports })
  }, [])

  const updateReport = React.useCallback((reportType: DriverReportDTO.TypeEnum, report: DriverReportDTO) => {
    const updatedVendorAccountingReports = { ...(vendorAccountingReportsRef.current || {}) }

    updatedVendorAccountingReports[reportType] = {
      ...(updatedVendorAccountingReports[reportType] || {}),
      [report.id]: report
    }

    mergeDataProps({ vendorAccountingReports: updatedVendorAccountingReports })
  }, [])

  const createReport = React.useCallback(
    async (reportType: DriverReportDTO.TypeEnum) => {
      const temporaryReport: DriverReportDTO = {
        id: createId(),
        status: DriverReportDTO.StatusEnum.PENDING,
        type: reportType,
        date: dateService.createStringDate.now
      }

      updateReport(reportType, temporaryReport)

      const requestedReport = await createDriverReport(reportType, driver).catch(() => null)

      deleteReportId(reportType, temporaryReport.id)

      if (requestedReport) {
        updateReport(reportType, requestedReport)
      }
    },
    [driver, updateReport]
  )

  const requestReports = React.useCallback(
    async (_props?: { spinner?: boolean }) => {
      if (_props && _props.spinner && setFetching) {
        setFetching(true)
      }

      await Promise.all([
        requestDriverReports(DriverReportDTO.TypeEnum.MVR, driver.authUserId),
        requestDriverReports(DriverReportDTO.TypeEnum.PSP, driver.authUserId)
      ])
        .then(([mvpReports, pspReports]) => {
          const updatedVendorAccountingReports = { ...(vendorAccountingReportsRef.current || {}) }
          const getReportsMapping = (reports: DriverReportDTO[]) => {
            const reportsMapping: Record<string, DriverReportDTO> = {}

            reports.forEach(report => {
              reportsMapping[report.id] = report
            })

            return reportsMapping
          }
          const vendorAccountingReports = {
            [DriverReportDTO.TypeEnum.MVR]: getReportsMapping(mvpReports),
            [DriverReportDTO.TypeEnum.PSP]: getReportsMapping(pspReports)
          }

          // @ts-ignore
          Object.keys(vendorAccountingReports).forEach((reportType: DriverReportDTO.TypeEnum) => {
            if (vendorAccountingReports[reportType]) {
              updatedVendorAccountingReports[reportType] = {
                ...(updatedVendorAccountingReports[reportType] || {}),
                ...vendorAccountingReports[reportType]
              }
            }
          })

          mergeDataProps({ vendorAccountingReports: updatedVendorAccountingReports })
          setFetchError(false)
        })
        .catch(() => setFetchError(true))

      if (_props && _props.spinner && setFetching) {
        setFetching(false)
      }
    },
    [oc(driver).authUserId()]
  )

  React.useEffect(() => {
    if (isModifiedMode) {
      return
    }

    if (
      !vendorAccountingReportsRef.current &&
      [DriverViewDTO.DriverTypeEnum.COMPANY, DriverViewDTO.DriverTypeEnum.OWNEROPERATOR].includes(driver.driverType)
    ) {
      requestReports()
    }
  }, [isModifiedMode, oc(driver).authUserId()])

  const claculatedReports = React.useMemo(() => {
    const reportTypes = [DriverReportDTO.TypeEnum.MVR, DriverReportDTO.TypeEnum.PSP]
    const result: IReports = {
      history: {}
    }

    reportTypes.forEach(reportType => {
      const reports = Object.values(oc(vendorAccountingReportsRef.current)[reportType]({})).filter(
        report => !report.isReset
      )
      const sortedReports = reports.sort((report1, report2) => Date.parse(report2.date) - Date.parse(report1.date))
      const history = sortedReports.filter(
        report =>
          report.url && ![DriverReportDTO.StatusEnum.PENDING, DriverReportDTO.StatusEnum.FAILED].includes(report.status)
      )

      result[reportType] = sortedReports[0]
      result.history[reportType] = history
    })

    return result
  }, [oc(data).vendorAccountingReports()])

  return (
    <VendorAccountingReportsContext.Provider
      value={{ reports: claculatedReports, createReport, updateReport, fetchError, requestReports }}
    >
      {children}
    </VendorAccountingReportsContext.Provider>
  )
})
