import * as DOMPurify from 'dompurify'
import { convertToHTML } from 'draft-convert'
import { RawDraftContentState, convertFromRaw } from 'draft-js'
import HTMLReactParser from 'html-react-parser'
import { oc } from 'ts-optchain'

export const generateHTMLFormDraftJS = (draftJSText: RawDraftContentState): JSX.Element | JSX.Element[] | undefined => {
  try {
    if (!draftJSText) {
      return undefined
    } else if (Boolean(oc(draftJSText as RawDraftContentState).blocks([]).length)) {
      const draftJSData = convertFromRaw(draftJSText)

      if (draftJSData && draftJSData.hasText()) {
        return HTMLReactParser(DOMPurify.sanitize(convertToHTML({})(draftJSData as any)), undefined)
      }
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
    return undefined
  }
}
