import * as R from 'remeda'
import { callAPI, driverAPI, DriverNameDTO, driverReportAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { DriverViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { vendorTypes } from '../../../../constants/Vendor'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { isNewObject } from '../../../../services/DTO'
import { DriverReportDTO, DriverReportGenerationRequestDTO } from '../../../../api/origin/document-service'
import { dateService } from '../../../../services/timeService'
import { BUSINESS_LOGIC_API_BASE_PATH } from '../../../../api/businessLogicApi'
import { getDefaultHeaders } from '../../../../api/defaultHeaders'

export const requestDriverById = (id: string): Promise<DriverViewDTO> => {
  return callAPI(driverAPI.findById, id)
    .toPromise()
    .then(async driver => {
      await pushListItemsToStore({ update: { [EntityType.driver]: [driver] } })

      return driver
    })
}

export const requestSimpleDrivers = async ({ sort, filter }: any): Promise<DriverViewDTO[]> => {
  return callAPI(driverAPI.findAllSimple, filter, sort)
    .toPromise()
    .then(async drivers => {
      await pushListItemsToStore({ update: { [EntityType.driver]: drivers } })
      return drivers
    })
    .catch(catchListErrors)
}

export const requestDrivers = async ({ sort, filter }: any): Promise<DriverViewDTO[]> => {
  return callAPI(driverAPI.findAll, filter, sort)
    .toPromise()
    .then(async drivers => {
      await pushListItemsToStore({ update: { [EntityType.driver]: drivers } })

      return drivers
    })
    .catch(catchListErrors)
}

export const requestVendors = async (): Promise<DriverViewDTO[]> => {
  return requestDrivers({
    filter: `driverType%%${vendorTypes.join(',')}`
  })
}

export const requestVendorByAuthUserId = async (authUserId: string): Promise<DriverViewDTO> => {
  const vendors = await requestDrivers({
    filter: `driverType%%${vendorTypes.join(',')};authUserId%%${authUserId}`
  })

  if (vendors && vendors.length) {
    return vendors[0]
  }
}

export const requestDriversByTerm = async (term: string, filterFunction?: (driver: DriverNameDTO) => boolean) => {
  return callAPI(driverAPI.search, 999, term.trim())
    .toPromise()
    .then(list => {
      if (!filterFunction) {
        return list
      }

      return list.filter(filterFunction)
    })
}

const omiDriverProps: (keyof DriverViewDTO)[] = []

export const requestCreateDriver = (driver: DriverViewDTO): Promise<DriverViewDTO> => {
  return callAPIWithErrorMessage(driverAPI.create, R.omit(driver, omiDriverProps.concat('id')) as DriverViewDTO).then(
    async requestedDriver => {
      await pushListItemsToStore({ update: { [EntityType.driver]: [requestedDriver] } })

      return requestedDriver
    }
  )
}

export const requestUpdateDriver = (driver: DriverViewDTO): Promise<DriverViewDTO> => {
  if (isNewObject(driver)) {
    return requestCreateDriver(driver)
  }

  return callAPIWithErrorMessage(driverAPI.update, driver.id, R.omit(driver, omiDriverProps) as DriverViewDTO).then(
    async requestedDriver => {
      await pushListItemsToStore({ update: { [EntityType.driver]: [requestedDriver] } })

      return requestedDriver
    }
  )
}
export const createDriverReport = (
  reportType: DriverReportDTO.TypeEnum,
  driver: DriverViewDTO
): Promise<DriverReportDTO> => {
  if (!(driver && driver.authUserId)) {
    return
  }

  const report: DriverReportGenerationRequestDTO = {
    type: reportType,
    firstName: driver.firstName,
    lastName: driver.lastName,
    dateOfBirth: dateService.getStringDay(driver.birthday),
    email: driver.email,
    phone: driver.phone,
    licenseIssueStateId: driver.licenseIssueStateId,
    licenseNumber: driver.licenseNumber
  }

  return callAPIWithErrorMessage(driverReportAPI.generateReport, report, driver.authUserId)
}

export const requestDriverReport = (
  reportType: DriverReportDTO.TypeEnum,
  authUserId: string
): Promise<DriverReportDTO> => {
  // @ts-ignore
  return callAPIWithErrorMessage(driverReportAPI.getReport, reportType, authUserId)
}

export const requestDriverReports = (
  reportType: DriverReportDTO.TypeEnum,
  authUserId: string
): Promise<DriverReportDTO[]> => {
  // @ts-ignore
  return callAPI(driverReportAPI.getReportHistory, reportType, authUserId)
    .toPromise()
    .catch(() => [] as DriverReportDTO[])
}

export const requestDriverReset = (id: string): Promise<DriverViewDTO> => {
  const request = (_id: string) =>
    fetch(`${BUSINESS_LOGIC_API_BASE_PATH}/driver/${_id}/reset`, { headers: getDefaultHeaders(), method: 'PUT' }).then(
      response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      }
    )
  // @ts-ignore
  return callAPIWithErrorMessage(request, id).then(async requestedDriver => {
    await pushListItemsToStore({ update: { [EntityType.driver]: [requestedDriver] } })

    return requestedDriver
  })
}
