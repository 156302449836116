import { useEffect, useMemo } from 'react'
import { oc } from 'ts-optchain'
import { selectCommunictaionHubChannelMeta } from '../store/select/communicationHubSelect'
import { useAppSelector } from './useAppSelector'
import { requestChatChannelsCounters } from '../components/CommunicationHub/epics'

interface ICommunicationHubChannel {
  hasUnreadMessages: boolean
  hasPinnedMessages: boolean
  hasMentionMessages: boolean
}

type Props = {
  channelId: string
}

export const useCommunicationHubChannelIndicators = (props: Props): ICommunicationHubChannel => {
  const { channelId } = props
  const channel = useAppSelector(selectCommunictaionHubChannelMeta(channelId))

  useEffect(() => {
    if (!channel) {
      requestChatChannelsCounters([channelId])
    }
  }, [channelId])

  const value = useMemo(() => {
    const isMute = oc(channel).mute(false)
    const allUnreadMessagesCount = oc(channel).count(0)
    const hasMentionMessages = Boolean(oc(channel).mentionMsgIds([]).length)
    const hasPinnedMessages = Boolean(oc(channel).pinnedMsgIds([]).length)
    const hasUnreadMessages = Boolean((!isMute && allUnreadMessagesCount) || hasMentionMessages)

    return {
      hasUnreadMessages,
      hasPinnedMessages,
      hasMentionMessages
    }
  }, [channel])

  return value
}
