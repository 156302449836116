import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../../../styles/theme'
import { ChatTab } from '../../../../../../../store/reducers/communicationHub/interfaces'
import cn from 'classnames'
import { preventEvent, stopPropagationEvent } from '../../../../../../../services/functions/basic'
// tslint:disable:max-line-length
import { openCommunicationHubChannelByDDOId } from '../../../../../../CommunicationHub/functions/openCommunicationHubChannelByDDOId'
import {} from '../../../../../../../store/select/communicationHubSelect'
import { useCommunicationHubChannelIndicators } from '../../../../../../../hooks/useCommunicationHubChannelIndicators'

type OwnProps = {
  dispatchDeliveryOrderId: string
  hasAlerts: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const MessagesButton = React.memo((props: Props) => {
  const { hasAlerts, dispatchDeliveryOrderId } = props
  const { hasPinnedMessages, hasUnreadMessages } = useCommunicationHubChannelIndicators({
    channelId: dispatchDeliveryOrderId
  })

  const handleColumnClick = React.useCallback(
    (chatTab: ChatTab) => (event: any) => {
      preventEvent(event)
      openCommunicationHubChannelByDDOId(dispatchDeliveryOrderId, chatTab)
    },
    [dispatchDeliveryOrderId]
  )

  return (
    <Container className={'messagesButton'} onClick={stopPropagationEvent}>
      {hasAlerts && <AlertButton className={'mdi mdi-alert-decagram'} onClick={handleColumnClick(ChatTab.Alerts)} />}
      {hasPinnedMessages && (
        <NotesButton className={cn('mdi mdi-file-document')} onClick={handleColumnClick(ChatTab.Notes)} />
      )}
      <MessageButton
        className={cn('mdi mdi-message-text', {
          active: hasUnreadMessages
        })}
        onClick={handleColumnClick(ChatTab.Messages)}
      />
    </Container>
  )
})

const Container = styled.div`
  width: 35px;
  display: flex;
  flex-direction: column;
`
const Button = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #fcfdff;
  outline: 1px solid #dce6f5;
  color: #c9cddd;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
`
const MessageButton = styled(Button)`
  &.active {
    color: ${theme.colors.basicBlueColor};
    position: relative;

    &:after {
      content: '';
      height: 7px;
      width: 7px;
      display: block;
      background: ${theme.colors.defaultRed};
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 12px);
      right: 5px;
    }
  }

  &:hover {
    background-color: ${theme.colors.basicBlueColor};
    color: white;
  }
`

const AlertButton = styled(MessageButton)`
  background-color: #ffeaea;
  color: ${theme.colors.defaultRed};
  outline-color: ${theme.colors.defaultRed};
  margin-bottom: 3px;

  &:hover {
    background-color: ${theme.colors.defaultRed};
  }
`

const NotesButton = styled(MessageButton)`
  margin-bottom: 3px;

  &:not(:hover) {
    color: #eaae76;
  }

  &:hover {
    background-color: #eaae76;
  }
`
