import * as React from 'react'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { ActionButton } from '../../../../UI/Buttons/ActionButton'

type Props = {}

export const TestSentry = React.memo((props: Props) => {
  const {} = props

  return (
    <Container>
      <Title>Sentry</Title>
      <ActionButton
        round={true}
        onClick={() => Sentry.captureException(new Error('Test Error Message'))}
        children={'Send Test Error Message'}
      />
    </Container>
  )
})

const Container = styled.div`
  margin: 40px 0 0;
`
const Title = styled.div`
  margin-bottom: 15px;
`
