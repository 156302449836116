import { GeneralCounterDTO, UserCounterDTO } from '../../../api/origin/communication-hub-service'
import { IChannelMeta } from '../../../store/reducers/communicationHub/interfaces'
import { generateChannelMeta } from '../generate/generateChannelMeta'
import { getMappingById } from '../get/getMappingById'

type Props = {
  generalCounters: GeneralCounterDTO[]
  userCounters: UserCounterDTO[]
  requestChannelIds?: string[]
}

export const mergeChannelCounters = (props: Props): IChannelMeta[] => {
  const { generalCounters, userCounters, requestChannelIds } = props
  const userCountersMapping = getMappingById(userCounters)

  if (requestChannelIds && requestChannelIds.length) {
    const generalCountersMapping = getMappingById(generalCounters)

    return requestChannelIds.map(id => {
      return generateChannelMeta({ id, ...(generalCountersMapping[id] || {}), ...(userCountersMapping[id] || {}) })
    })
  } else {
    return generalCounters.map(item => generateChannelMeta({ ...item, ...(userCountersMapping[item.id] || {}) }))
  }
}
