import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { preventEvent } from '../../../../../services/functions/basic'
import { leaveChatChannelIfNoEditing } from '../../../../CommunicationHub/functions/leaveChatChannelIfNoEditing'
import { ChatTab } from '../../../../../store/reducers/communicationHub/interfaces'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import {
  selectCommunictaionHubChannelHasNewMasseges,
  selectCommunictaionHubChannelHasNewNotes
} from '../../../../../store/select/communicationHubSelect'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../../store/reducers/communicationHub'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
// tslint:disable:max-line-length
import { generateDispatchDeliveryOrderChatChannel } from '../../../../../services/functions/generate/generateDispatchDeliveryOrderChatChannel'
import { useCommunicationHubChannelIndicators } from '../../../../../hooks/useCommunicationHubChannelIndicators'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NotificationsColumn = React.memo((props: Props) => {
  const { dispatchDeliveryOrder } = useGridItemDDO()
  const dispatch = useAppDispatch()
  const hasNewMessages = useAppSelector(selectCommunictaionHubChannelHasNewMasseges(dispatchDeliveryOrder.id))
  const { hasUnreadMessages, hasPinnedMessages } = useCommunicationHubChannelIndicators({
    channelId: dispatchDeliveryOrder.id
  })
  const hasAlert = dispatchDeliveryOrder.troubleTicketId

  const handleColumnClick = React.useCallback(
    (chatTab: ChatTab) => (event: any) => {
      preventEvent(event)

      leaveChatChannelIfNoEditing(() =>
        dispatch(
          communicationHubActions.pushActiveChannel({
            channel: generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder),
            options: { chatTab }
          })
        )
      )
    },
    [dispatchDeliveryOrder]
  )

  return (
    <Container>
      <Alerts
        className={cn('mdi mdi-alert-decagram', { active: hasAlert })}
        onClick={handleColumnClick(ChatTab.Alerts)}
      />
      <Notes
        className={cn('mdi mdi-file-document', { active: hasPinnedMessages })}
        onClick={handleColumnClick(ChatTab.Notes)}
      />
      <Messages
        className={cn('mdi mdi-message-text', { active: hasUnreadMessages })}
        onClick={handleColumnClick(ChatTab.Messages)}
      />
    </Container>
  )
})

const Icon = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: white !important;
  }
`
const Alerts = styled(Icon)`
  &.active {
    color: ${theme.colors.defaultRed};
  }

  &:hover {
    background-color: ${theme.colors.defaultRed};
  }
`
const Notes = styled(Icon)`
  &.active {
    color: #eaae76;
  }

  &:hover {
    background-color: #eaae76;
  }
`
const Messages = styled(Icon)`
  &.active {
    color: ${theme.colors.basicBlueColor};
    position: relative;

    &:after {
      content: '';
      height: 7px;
      width: 7px;
      display: block;
      background: ${theme.colors.defaultRed};
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      right: 5px;
    }
  }

  &:hover {
    background-color: ${theme.colors.basicBlueColor};
  }
`
const Container = styled.div`
  width: 100%;
  align-self: stretch;
  display: flex;
  align-items: stretch;
  font-size: 18px;
  color: #c9cddf;
`
