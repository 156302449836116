import axios from 'axios'
import * as R from 'remeda'
import * as Sentry from '@sentry/browser'
import { AUTH_API_BASE_PATH } from '../../api/authApi'
import { AuthConfig, AUTHORIZATION_HEADERS, AuthorizationGrantTypes, authRedirect } from '../authService'
import { callAPI } from '../../api/api'
import {
  getItemFromStorage,
  removeItemFromStorage,
  saveItemToStorage,
  StorageKeys
} from '../storageService/storageService'
import { getStore } from '../../store/configureStore'
import { authGetTokens } from '../authService/actions/authServiceActions'
import { isSentryHandleFetchErrorStatus } from './test/isSentryHandleFetchErrorStatus'

const axiosAuthIstance = axios.create({
  baseURL: `${AUTH_API_BASE_PATH}/realms/${AuthConfig.audience}/protocol/openid-connect`
})

axiosAuthIstance.interceptors.request.use(
  _config => {
    return _config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosAuthIstance.interceptors.response.use(
  response => response,
  error => {
    // Do something with response error
    // const errorData = error.response.data
    //
    // const pushError = (err: any) => {
    //   getStore().dispatch(addServerMessage({ type: 'error', message: convertErrorToMessage(err) }))
    // }
    //
    // if (Array.isArray(errorData)) {
    //   errorData.forEach(pushError)
    // } else {
    //   pushError(errorData)
    // }

    if (isSentryHandleFetchErrorStatus(error.response.status)) {
      Sentry.captureException(error.response.data)
    }

    return Promise.reject(error.response)
  }
)

export const refreshAuthToken = async () => {
  const params = new URLSearchParams()
  params.append('grant_type', AuthorizationGrantTypes.RefreshToken)
  params.append('client_id', AuthConfig.client_id)
  params.append('refresh_token', getItemFromStorage(StorageKeys.RefreshToken))

  return callAPI(() =>
    axiosAuthIstance.post(`/token`, params, {
      headers: R.clone(AUTHORIZATION_HEADERS)
    })
  )
    .toPromise()
    .then(response => {
      if (!(response && response.data)) {
        throw new Error('refreshAuthToken Error')
      }

      const { refresh_token, access_token, id_token } = response.data as any

      if (refresh_token && access_token && id_token) {
        saveItemToStorage(StorageKeys.RefreshToken, refresh_token)
        saveItemToStorage(StorageKeys.AccessToken, access_token)
        saveItemToStorage(StorageKeys.IdToken, id_token)
        getStore().dispatch(authGetTokens.done({}))

        return response
      }
    })
    .catch(() => {
      removeItemFromStorage(StorageKeys.RefreshToken)
      removeItemFromStorage(StorageKeys.AccessToken)
      removeItemFromStorage(StorageKeys.IdToken)
      authRedirect()
    })
}
