import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridItemDriverContext } from '../contexts/GridItemDriverContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useDriver } from '../hooks/useDriver'
import { DriverViewDTO } from '../api/api'
import { isSimpleValidation as testIsSimpleValidation } from '../services/functions/test/isSimpleValidation'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { GridItemTab } from '../components/pages/GridItem/interfaces'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemDriverProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const { activeTab, setActiveTab } = useExpandedItem()
  const driver = useDriver({ id, modifiedLists })
  const driverType = oc(driver).driverType()
  const isDriverTypeFilled = Boolean(driverType)
  const isDriver =
    driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR || driverType === DriverViewDTO.DriverTypeEnum.COMPANY
  const isSimpleValidation = testIsSimpleValidation(driver)

  React.useEffect(() => {
    if (!isDriverTypeFilled) {
      if (activeTab !== GridItemTab.Driver.General) {
        setActiveTab(GridItemTab.Driver.General)
      }
    }
  }, [isDriverTypeFilled])

  return (
    <GridItemDriverContext.Provider
      value={{
        driver,
        isExpanded: Boolean(modifiedLists),
        isDriverTypeFilled,
        isDriver,
        isSimpleValidation
      }}
      children={children}
    />
  )
})
