// tslint:disable
/**
 * C-Hub Express API with Swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url'

import { Configuration } from './configuration'

const BASE_PATH = 'https://localhost'.replace(/\/+$/, '')

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration

  constructor(configuration?: Configuration, protected basePath: string = configuration.basePath) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

/**
 *
 * @export
 * @interface ActivityDTO
 */
export interface ActivityDTO {
  /**
   *
   * @type {DestinationDTO}
   * @memberof ActivityDTO
   */
  destination?: DestinationDTO
  /**
   *
   * @type {string}
   * @memberof ActivityDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ActivityDTO
   */
  stage?: string
  /**
   *
   * @type {DateTimeRangeDTO}
   * @memberof ActivityDTO
   */
  startPlannedDateTimeRange?: DateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof ActivityDTO
   */
  type?: ActivityDTO.TypeEnum
  /**
   *
   * @type {VendorDTO}
   * @memberof ActivityDTO
   */
  vendor?: VendorDTO
}

/**
 * @export
 * @namespace ActivityDTO
 */
export namespace ActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  stateCode?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  stateId?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  street2?: string
}

/**
 *
 * @export
 * @interface AlertDTO
 */
export interface AlertDTO {
  /**
   *
   * @type {string}
   * @memberof AlertDTO
   */
  ddoId: string
  /**
   *
   * @type {DocumentMetadataDTO}
   * @memberof AlertDTO
   */
  documentMetadata?: DocumentMetadataDTO
  /**
   *
   * @type {string}
   * @memberof AlertDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof AlertDTO
   */
  status: AlertDTO.StatusEnum
  /**
   *
   * @type {DocumentValidationDTO}
   * @memberof AlertDTO
   */
  validation?: DocumentValidationDTO
}

/**
 * @export
 * @namespace AlertDTO
 */
export namespace AlertDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    RESOLVE = <any>'RESOLVE'
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AlertType {
  DEFAULT = <any>'DEFAULT',
  TIR = <any>'TIR',
  POD = <any>'POD'
}

/**
 *
 * @export
 * @interface AnswersDTO
 */
export interface AnswersDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof AnswersDTO
   */
  answerIds?: Array<string>
}

/**
 *
 * @export
 * @interface BatchCreateRepliesDTO
 */
export interface BatchCreateRepliesDTO {
  /**
   *
   * @type {Array<CreateNotificationReplyDTO>}
   * @memberof BatchCreateRepliesDTO
   */
  replies: Array<CreateNotificationReplyDTO>
}

/**
 *
 * @export
 * @interface ChannelDTO
 */
export interface ChannelDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ChannelDTO
   */
  alertMsgIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof ChannelDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ChannelDTO
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof ChannelDTO
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof ChannelDTO
   */
  name: string
  /**
   *
   * @type {Array<string>}
   * @memberof ChannelDTO
   */
  pinnedMsgIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof ChannelDTO
   */
  updatedAt?: DateISOString
}

/**
 *
 * @export
 * @interface ContainerDTO
 */
export interface ContainerDTO {
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  number?: string
}

/**
 *
 * @export
 * @interface CountersDTO
 */
export interface CountersDTO {
  /**
   *
   * @type {Array<GeneralCounterDTO>}
   * @memberof CountersDTO
   */
  generalCounters: Array<GeneralCounterDTO>
  /**
   *
   * @type {Array<UserCounterDTO>}
   * @memberof CountersDTO
   */
  userCounters: Array<UserCounterDTO>
}

/**
 *
 * @export
 * @interface CreateAlertDTO
 */
export interface CreateAlertDTO {
  /**
   *
   * @type {Array<FileDataDTO>}
   * @memberof CreateAlertDTO
   */
  attachments?: Array<FileDataDTO>
  /**
   *
   * @type {string}
   * @memberof CreateAlertDTO
   */
  authUserId: string
  /**
   *
   * @type {ContainerDTO}
   * @memberof CreateAlertDTO
   */
  container?: ContainerDTO
  /**
   *
   * @type {string}
   * @memberof CreateAlertDTO
   */
  ddoId: string
  /**
   *
   * @type {string}
   * @memberof CreateAlertDTO
   */
  ddoNumber: string
  /**
   *
   * @type {DocumentMetadataDTO}
   * @memberof CreateAlertDTO
   */
  documentMetadata?: DocumentMetadataDTO
  /**
   *
   * @type {string}
   * @memberof CreateAlertDTO
   */
  notes?: string
  /**
   *
   * @type {any}
   * @memberof CreateAlertDTO
   */
  params: any
  /**
   *
   * @type {string}
   * @memberof CreateAlertDTO
   */
  troubleTicketId: string
  /**
   *
   * @type {AlertType}
   * @memberof CreateAlertDTO
   */
  type: AlertType
  /**
   *
   * @type {DocumentValidationDTO}
   * @memberof CreateAlertDTO
   */
  validation?: DocumentValidationDTO
}

/**
 *
 * @export
 * @interface CreateChannelDTO
 */
export interface CreateChannelDTO {
  /**
   *
   * @type {string}
   * @memberof CreateChannelDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateChannelDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface CreateDocumentDTO
 */
export interface CreateDocumentDTO {
  /**
   *
   * @type {Array<FileDataDTO>}
   * @memberof CreateDocumentDTO
   */
  attachments?: Array<FileDataDTO>
  /**
   *
   * @type {string}
   * @memberof CreateDocumentDTO
   */
  channelId: string
  /**
   *
   * @type {MetadataDTO}
   * @memberof CreateDocumentDTO
   */
  metadata?: MetadataDTO
  /**
   *
   * @type {string}
   * @memberof CreateDocumentDTO
   */
  senderId: string
}

/**
 *
 * @export
 * @interface CreateMessageDTO
 */
export interface CreateMessageDTO {
  /**
   *
   * @type {Array<any>}
   * @memberof CreateMessageDTO
   */
  attachments?: Array<any>
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  channelId: string
  /**
   *
   * @type {any}
   * @memberof CreateMessageDTO
   */
  externalData?: any
  /**
   *
   * @type {boolean}
   * @memberof CreateMessageDTO
   */
  isSMS?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CreateMessageDTO
   */
  mentionUserIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  messageSid?: string
  /**
   *
   * @type {MetadataDTO}
   * @memberof CreateMessageDTO
   */
  metadata?: MetadataDTO
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  senderId: string
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  smsStatus?: CreateMessageDTO.SmsStatusEnum
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  status?: CreateMessageDTO.StatusEnum
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof CreateMessageDTO
   */
  text: RawDraftContentStateDTO
  /**
   *
   * @type {string}
   * @memberof CreateMessageDTO
   */
  type?: CreateMessageDTO.TypeEnum
}

/**
 * @export
 * @namespace CreateMessageDTO
 */
export namespace CreateMessageDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum SmsStatusEnum {
    UNSENT = <any>'UNSENT',
    SENT = <any>'SENT',
    DELIVERED = <any>'DELIVERED',
    UNDELIVERED = <any>'UNDELIVERED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    RESOLVE = <any>'RESOLVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    MESSAGE = <any>'MESSAGE',
    SYSTEM = <any>'SYSTEM',
    WORKORDER = <any>'WORKORDER',
    ALERT = <any>'ALERT',
    DOCUMENT = <any>'DOCUMENT'
  }
}

/**
 *
 * @export
 * @interface CreateNotificationDTO
 */
export interface CreateNotificationDTO {
  /**
   *
   * @type {string}
   * @memberof CreateNotificationDTO
   */
  documentType?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNotificationDTO
   */
  driverStatuses: Array<CreateNotificationDTO.DriverStatusesEnum>
  /**
   *
   * @type {Array<NotificationOptionDTO>}
   * @memberof CreateNotificationDTO
   */
  options?: Array<NotificationOptionDTO>
  /**
   *
   * @type {string}
   * @memberof CreateNotificationDTO
   */
  subject: string
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof CreateNotificationDTO
   */
  text?: RawDraftContentStateDTO
  /**
   *
   * @type {string}
   * @memberof CreateNotificationDTO
   */
  type: CreateNotificationDTO.TypeEnum
}

/**
 * @export
 * @namespace CreateNotificationDTO
 */
export namespace CreateNotificationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverStatusesEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    SIMPLE = <any>'SIMPLE',
    ACKNOWLEDGMENT = <any>'ACKNOWLEDGMENT',
    POLL = <any>'POLL',
    RESIGNDOCUMENT = <any>'RESIGN_DOCUMENT'
  }
}

/**
 *
 * @export
 * @interface CreateNotificationReplyDTO
 */
export interface CreateNotificationReplyDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNotificationReplyDTO
   */
  answerIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreateNotificationReplyDTO
   */
  notificationId: string
}

/**
 *
 * @export
 * @interface DateTimeRangeDTO
 */
export interface DateTimeRangeDTO {
  /**
   *
   * @type {string}
   * @memberof DateTimeRangeDTO
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof DateTimeRangeDTO
   */
  to?: string
}

/**
 *
 * @export
 * @interface DestinationDTO
 */
export interface DestinationDTO {
  /**
   *
   * @type {AddressDTO}
   * @memberof DestinationDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof DestinationDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DestinationDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DestinationDTO
   */
  type?: DestinationDTO.TypeEnum
}

/**
 * @export
 * @namespace DestinationDTO
 */
export namespace DestinationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface DocumentMetadataDTO
 */
export interface DocumentMetadataDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityType?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderNumber: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  type?: string
}

/**
 *
 * @export
 * @interface DocumentValidationDTO
 */
export interface DocumentValidationDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  bookingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  dispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  isValidatedManually?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  message?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentValidationDTO
   */
  messages?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedChassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedContainerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedDispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  referenceNumberRecognized?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  signatureRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  status: string
}

/**
 *
 * @export
 * @interface DriverCounterDTO
 */
export interface DriverCounterDTO {
  /**
   *
   * @type {string}
   * @memberof DriverCounterDTO
   */
  containerNumber?: string
  /**
   *
   * @type {number}
   * @memberof DriverCounterDTO
   */
  count: number
  /**
   *
   * @type {Array<string>}
   * @memberof DriverCounterDTO
   */
  destinations: Array<string>
  /**
   *
   * @type {string}
   * @memberof DriverCounterDTO
   */
  id: string
  /**
   *
   * @type {LastMessageDTO}
   * @memberof DriverCounterDTO
   */
  lastMessage: LastMessageDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverCounterDTO
   */
  updatedAt: DateISOString
}

/**
 *
 * @export
 * @interface DriverInfoDTO
 */
export interface DriverInfoDTO {
  /**
   *
   * @type {Array<ActivityDTO>}
   * @memberof DriverInfoDTO
   */
  activities: Array<ActivityDTO>
  /**
   *
   * @type {string}
   * @memberof DriverInfoDTO
   */
  authUserId: string
  /**
   *
   * @type {string}
   * @memberof DriverInfoDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DriverInfoDTO
   */
  message: string
}

/**
 *
 * @export
 * @interface DriverInviteDTO
 */
export interface DriverInviteDTO {
  /**
   *
   * @type {string}
   * @memberof DriverInviteDTO
   */
  authUserId: string
  /**
   *
   * @type {string}
   * @memberof DriverInviteDTO
   */
  driverId: string
}

/**
 *
 * @export
 * @interface DriverNotificationDTO
 */
export interface DriverNotificationDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof DriverNotificationDTO
   */
  createdAt: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverNotificationDTO
   */
  documentType?: string
  /**
   *
   * @type {string}
   * @memberof DriverNotificationDTO
   */
  id: string
  /**
   *
   * @type {Array<NotificationOptionDTO>}
   * @memberof DriverNotificationDTO
   */
  options?: Array<NotificationOptionDTO>
  /**
   *
   * @type {NotificationReplyDTO}
   * @memberof DriverNotificationDTO
   */
  reply?: NotificationReplyDTO
  /**
   *
   * @type {string}
   * @memberof DriverNotificationDTO
   */
  status: DriverNotificationDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DriverNotificationDTO
   */
  subject: string
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof DriverNotificationDTO
   */
  text?: RawDraftContentStateDTO
  /**
   *
   * @type {string}
   * @memberof DriverNotificationDTO
   */
  type: DriverNotificationDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DriverNotificationDTO
   */
  updatedAt: DateISOString
}

/**
 * @export
 * @namespace DriverNotificationDTO
 */
export namespace DriverNotificationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    INPROGRESS = <any>'IN_PROGRESS',
    COMPLETED = <any>'COMPLETED',
    DELETED = <any>'DELETED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    SIMPLE = <any>'SIMPLE',
    ACKNOWLEDGMENT = <any>'ACKNOWLEDGMENT',
    POLL = <any>'POLL',
    RESIGNDOCUMENT = <any>'RESIGN_DOCUMENT'
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DriverStatus {
  AVAILABLE = <any>'AVAILABLE',
  NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
  NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
  INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
  INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
  INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
  INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
  INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
  INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
  INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
  POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
  OFFDUTY = <any>'OFF_DUTY',
  CANDIDATE = <any>'CANDIDATE',
  CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
  CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
  CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED'
}

/**
 *
 * @export
 * @interface FileDataDTO
 */
export interface FileDataDTO {
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  contentType?: string
  /**
   *
   * @type {Array<any>}
   * @memberof FileDataDTO
   */
  converted?: Array<any>
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof FileDataDTO
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  thumb?: string
  /**
   *
   * @type {string}
   * @memberof FileDataDTO
   */
  url: string
}

/**
 *
 * @export
 * @interface GeneralCounterDTO
 */
export interface GeneralCounterDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof GeneralCounterDTO
   */
  alertMsgIds: Array<string>
  /**
   *
   * @type {number}
   * @memberof GeneralCounterDTO
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof GeneralCounterDTO
   */
  id: string
  /**
   *
   * @type {Array<string>}
   * @memberof GeneralCounterDTO
   */
  pinnedMsgIds: Array<string>
  /**
   *
   * @type {string}
   * @memberof GeneralCounterDTO
   */
  type: GeneralCounterDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof GeneralCounterDTO
   */
  updatedAt: DateISOString
}

/**
 * @export
 * @namespace GeneralCounterDTO
 */
export namespace GeneralCounterDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DDO = <any>'DDO'
  }
}

/**
 *
 * @export
 * @interface GeoDataDTO
 */
export interface GeoDataDTO {
  /**
   *
   * @type {number}
   * @memberof GeoDataDTO
   */
  latitude: number
  /**
   *
   * @type {number}
   * @memberof GeoDataDTO
   */
  longitude: number
}

/**
 *
 * @export
 * @interface GetNotificationListDTO
 */
export interface GetNotificationListDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof GetNotificationListDTO
   */
  driverStatuses?: Array<GetNotificationListDTO.DriverStatusesEnum>
  /**
   *
   * @type {LocalDateTime}
   * @memberof GetNotificationListDTO
   */
  endDate?: LocalDateTime
  /**
   *
   * @type {number}
   * @memberof GetNotificationListDTO
   */
  limit: number
  /**
   *
   * @type {number}
   * @memberof GetNotificationListDTO
   */
  page: number
  /**
   *
   * @type {Array<string>}
   * @memberof GetNotificationListDTO
   */
  recipientUserIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof GetNotificationListDTO
   */
  senderUserIds?: Array<string>
  /**
   *
   * @type {LocalDateTime}
   * @memberof GetNotificationListDTO
   */
  startDate?: LocalDateTime
  /**
   *
   * @type {string}
   * @memberof GetNotificationListDTO
   */
  status?: GetNotificationListDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof GetNotificationListDTO
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof GetNotificationListDTO
   */
  type?: GetNotificationListDTO.TypeEnum
}

/**
 * @export
 * @namespace GetNotificationListDTO
 */
export namespace GetNotificationListDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverStatusesEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    INPROGRESS = <any>'IN_PROGRESS',
    COMPLETED = <any>'COMPLETED',
    DELETED = <any>'DELETED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    SIMPLE = <any>'SIMPLE',
    ACKNOWLEDGMENT = <any>'ACKNOWLEDGMENT',
    POLL = <any>'POLL',
    RESIGNDOCUMENT = <any>'RESIGN_DOCUMENT'
  }
}

/**
 *
 * @export
 * @interface InviteDTO
 */
export interface InviteDTO {
  /**
   *
   * @type {string}
   * @memberof InviteDTO
   */
  authUserId: string
  /**
   *
   * @type {string}
   * @memberof InviteDTO
   */
  driverId: string
  /**
   *
   * @type {string}
   * @memberof InviteDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof InviteDTO
   */
  text: string
}

/**
 *
 * @export
 * @interface LastMessageDTO
 */
export interface LastMessageDTO {
  /**
   *
   * @type {string}
   * @memberof LastMessageDTO
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof LastMessageDTO
   */
  id: string
  /**
   *
   * @type {MetadataDTO}
   * @memberof LastMessageDTO
   */
  metadata?: MetadataDTO
  /**
   *
   * @type {string}
   * @memberof LastMessageDTO
   */
  senderId: string
  /**
   *
   * @type {string}
   * @memberof LastMessageDTO
   */
  status?: LastMessageDTO.StatusEnum
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof LastMessageDTO
   */
  text: RawDraftContentStateDTO
  /**
   *
   * @type {MessageType}
   * @memberof LastMessageDTO
   */
  type: MessageType
}

/**
 * @export
 * @namespace LastMessageDTO
 */
export namespace LastMessageDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    RESOLVE = <any>'RESOLVE'
  }
}

/**
 *
 * @export
 * @interface LocalDateTime
 */
export interface LocalDateTime {}

/**
 *
 * @export
 * @interface MessageDTO
 */
export interface MessageDTO {
  /**
   *
   * @type {Array<any>}
   * @memberof MessageDTO
   */
  attachments?: Array<any>
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  channelId: string
  /**
   *
   * @type {DateISOString}
   * @memberof MessageDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {number}
   * @memberof MessageDTO
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  errorMessage?: string
  /**
   *
   * @type {any}
   * @memberof MessageDTO
   */
  externalData?: any
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof MessageDTO
   */
  isDeleted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof MessageDTO
   */
  isPinned?: boolean
  /**
   *
   * @type {boolean}
   * @memberof MessageDTO
   */
  isSMS?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof MessageDTO
   */
  mentionUserIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  messageSid?: string
  /**
   *
   * @type {MetadataDTO}
   * @memberof MessageDTO
   */
  metadata?: MetadataDTO
  /**
   *
   * @type {Array<string>}
   * @memberof MessageDTO
   */
  readUserIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  senderId: string
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  smsStatus?: MessageDTO.SmsStatusEnum
  /**
   *
   * @type {string}
   * @memberof MessageDTO
   */
  status?: MessageDTO.StatusEnum
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof MessageDTO
   */
  text: RawDraftContentStateDTO
  /**
   *
   * @type {MessageType}
   * @memberof MessageDTO
   */
  type: MessageType
  /**
   *
   * @type {DateISOString}
   * @memberof MessageDTO
   */
  updatedAt?: DateISOString
}

/**
 * @export
 * @namespace MessageDTO
 */
export namespace MessageDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum SmsStatusEnum {
    UNSENT = <any>'UNSENT',
    SENT = <any>'SENT',
    DELIVERED = <any>'DELIVERED',
    UNDELIVERED = <any>'UNDELIVERED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    RESOLVE = <any>'RESOLVE'
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum MessageType {
  MESSAGE = <any>'MESSAGE',
  SYSTEM = <any>'SYSTEM',
  WORKORDER = <any>'WORKORDER',
  ALERT = <any>'ALERT',
  DOCUMENT = <any>'DOCUMENT'
}

/**
 *
 * @export
 * @interface MetadataDTO
 */
export interface MetadataDTO {
  /**
   *
   * @type {Array<ActivityDTO>}
   * @memberof MetadataDTO
   */
  activities?: Array<ActivityDTO>
  /**
   *
   * @type {ContainerDTO}
   * @memberof MetadataDTO
   */
  container?: ContainerDTO
  /**
   *
   * @type {MetadataDdoDTO}
   * @memberof MetadataDTO
   */
  ddo?: MetadataDdoDTO
  /**
   *
   * @type {DocumentMetadataDTO}
   * @memberof MetadataDTO
   */
  documentMetadata?: DocumentMetadataDTO
  /**
   *
   * @type {MetadataDriverDTO}
   * @memberof MetadataDTO
   */
  driver?: MetadataDriverDTO
  /**
   *
   * @type {GeoDataDTO}
   * @memberof MetadataDTO
   */
  geoData?: GeoDataDTO
  /**
   *
   * @type {Array<MoveDTO>}
   * @memberof MetadataDTO
   */
  moves?: Array<MoveDTO>
  /**
   *
   * @type {string}
   * @memberof MetadataDTO
   */
  notes?: string
  /**
   *
   * @type {any}
   * @memberof MetadataDTO
   */
  params?: any
  /**
   *
   * @type {string}
   * @memberof MetadataDTO
   */
  resolvedByUserId?: string
  /**
   *
   * @type {string}
   * @memberof MetadataDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {string}
   * @memberof MetadataDTO
   */
  troubleTicketType?: MetadataDTO.TroubleTicketTypeEnum
  /**
   *
   * @type {AlertType}
   * @memberof MetadataDTO
   */
  type?: AlertType
  /**
   *
   * @type {DocumentValidationDTO}
   * @memberof MetadataDTO
   */
  validation?: DocumentValidationDTO
}

/**
 * @export
 * @namespace MetadataDTO
 */
export namespace MetadataDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TroubleTicketTypeEnum {
    DEFAULT = <any>'DEFAULT',
    TIR = <any>'TIR',
    POD = <any>'POD'
  }
}

/**
 *
 * @export
 * @interface MetadataDdoDTO
 */
export interface MetadataDdoDTO {
  /**
   *
   * @type {string}
   * @memberof MetadataDdoDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MetadataDdoDTO
   */
  number?: string
}

/**
 *
 * @export
 * @interface MetadataDriverDTO
 */
export interface MetadataDriverDTO {
  /**
   *
   * @type {string}
   * @memberof MetadataDriverDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MetadataDriverDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface MoveDTO
 */
export interface MoveDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof MoveDTO
   */
  activityIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof MoveDTO
   */
  id?: string
}

/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof NotificationDTO
   */
  createdAt: DateISOString
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  createdBy: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  documentType?: string
  /**
   *
   * @type {Array<DriverStatus>}
   * @memberof NotificationDTO
   */
  driverStatuses: Array<DriverStatus>
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  id: string
  /**
   *
   * @type {any}
   * @memberof NotificationDTO
   */
  metadata?: any
  /**
   *
   * @type {Array<NotificationOptionDTO>}
   * @memberof NotificationDTO
   */
  options?: Array<NotificationOptionDTO>
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationDTO
   */
  recipientUserIds: Array<string>
  /**
   *
   * @type {Array<NotificationReplyDTO>}
   * @memberof NotificationDTO
   */
  replies: Array<NotificationReplyDTO>
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  senderUserId: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  status: NotificationDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  subject: string
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof NotificationDTO
   */
  text?: RawDraftContentStateDTO
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  type: NotificationDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof NotificationDTO
   */
  updatedAt: DateISOString
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  updatedBy: string
}

/**
 * @export
 * @namespace NotificationDTO
 */
export namespace NotificationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    INPROGRESS = <any>'IN_PROGRESS',
    COMPLETED = <any>'COMPLETED',
    DELETED = <any>'DELETED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    SIMPLE = <any>'SIMPLE',
    ACKNOWLEDGMENT = <any>'ACKNOWLEDGMENT',
    POLL = <any>'POLL',
    RESIGNDOCUMENT = <any>'RESIGN_DOCUMENT'
  }
}

/**
 *
 * @export
 * @interface NotificationOptionDTO
 */
export interface NotificationOptionDTO {
  /**
   *
   * @type {string}
   * @memberof NotificationOptionDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof NotificationOptionDTO
   */
  text: string
}

/**
 *
 * @export
 * @interface NotificationReplyDTO
 */
export interface NotificationReplyDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationReplyDTO
   */
  answerIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof NotificationReplyDTO
   */
  createdAt: DateISOString
  /**
   *
   * @type {string}
   * @memberof NotificationReplyDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof NotificationReplyDTO
   */
  notificationId: string
  /**
   *
   * @type {string}
   * @memberof NotificationReplyDTO
   */
  recipientUserId: string
}

/**
 *
 * @export
 * @interface PhoneNumberDTO
 */
export interface PhoneNumberDTO {
  /**
   *
   * @type {string}
   * @memberof PhoneNumberDTO
   */
  channelId: string
  /**
   *
   * @type {string}
   * @memberof PhoneNumberDTO
   */
  friendlyName?: string
  /**
   *
   * @type {string}
   * @memberof PhoneNumberDTO
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof PhoneNumberDTO
   */
  type: PhoneNumberDTO.TypeEnum
}

/**
 * @export
 * @namespace PhoneNumberDTO
 */
export namespace PhoneNumberDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    ATTACHED = <any>'ATTACHED',
    DETACHED = <any>'DETACHED'
  }
}

/**
 *
 * @export
 * @interface PhoneNumberInstance
 */
export interface PhoneNumberInstance {}

/**
 *
 * @export
 * @interface PhoneNumberStatDTO
 */
export interface PhoneNumberStatDTO {
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  attachedPhones: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  bindedFromSMSUrl: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  detachedPhones: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  numbersInPool: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  numbersOutOfPool: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  numbersWithoutSMSUrl: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  phoneNumbersWeekBefore: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  phoneNumbersYesterday: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  phoneSetupsLastWeek: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  phoneSetupsYesterday: number
  /**
   *
   * @type {number}
   * @memberof PhoneNumberStatDTO
   */
  totalNumbers: number
}

/**
 *
 * @export
 * @interface RawDraftContentBlockDTO
 */
export interface RawDraftContentBlockDTO {
  /**
   *
   * @type {any}
   * @memberof RawDraftContentBlockDTO
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof RawDraftContentBlockDTO
   */
  depth: number
  /**
   *
   * @type {Array<RawDraftEntityRangeDTO>}
   * @memberof RawDraftContentBlockDTO
   */
  entityRanges: Array<RawDraftEntityRangeDTO>
  /**
   *
   * @type {Array<any>}
   * @memberof RawDraftContentBlockDTO
   */
  inlineStyleRanges: Array<any>
  /**
   *
   * @type {string}
   * @memberof RawDraftContentBlockDTO
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof RawDraftContentBlockDTO
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof RawDraftContentBlockDTO
   */
  type: string
}

/**
 *
 * @export
 * @interface RawDraftContentStateDTO
 */
export interface RawDraftContentStateDTO {
  /**
   *
   * @type {Array<RawDraftContentBlockDTO>}
   * @memberof RawDraftContentStateDTO
   */
  blocks: Array<RawDraftContentBlockDTO>
  /**
   *
   * @type {any}
   * @memberof RawDraftContentStateDTO
   */
  entityMap: any
}

/**
 *
 * @export
 * @interface RawDraftEntityRangeDTO
 */
export interface RawDraftEntityRangeDTO {
  /**
   *
   * @type {number}
   * @memberof RawDraftEntityRangeDTO
   */
  key: number
  /**
   *
   * @type {number}
   * @memberof RawDraftEntityRangeDTO
   */
  length: number
  /**
   *
   * @type {number}
   * @memberof RawDraftEntityRangeDTO
   */
  offset: number
}

/**
 *
 * @export
 * @interface RemovePhoneNumbersDTO
 */
export interface RemovePhoneNumbersDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof RemovePhoneNumbersDTO
   */
  detachedPhoneNumbers?: Array<string>
  /**
   *
   * @type {any}
   * @memberof RemovePhoneNumbersDTO
   */
  details?: any
  /**
   *
   * @type {number}
   * @memberof RemovePhoneNumbersDTO
   */
  phoneNumbersCount: number
}

/**
 *
 * @export
 * @interface SearchChannelDTO
 */
export interface SearchChannelDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof SearchChannelDTO
   */
  channelIds: Array<string>
}

/**
 *
 * @export
 * @interface SearchCounterDTO
 */
export interface SearchCounterDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof SearchCounterDTO
   */
  channelIds: Array<string>
}

/**
 *
 * @export
 * @interface SearchDocumentDTO
 */
export interface SearchDocumentDTO {
  /**
   *
   * @type {string}
   * @memberof SearchDocumentDTO
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof SearchDocumentDTO
   */
  channelId: string
}

/**
 *
 * @export
 * @interface SendMmsDTO
 */
export interface SendMmsDTO {
  /**
   *
   * @type {string}
   * @memberof SendMmsDTO
   */
  fileName: string
  /**
   *
   * @type {string}
   * @memberof SendMmsDTO
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof SendMmsDTO
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof SendMmsDTO
   */
  to: string
}

/**
 *
 * @export
 * @interface SendSmsDTO
 */
export interface SendSmsDTO {
  /**
   *
   * @type {string}
   * @memberof SendSmsDTO
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof SendSmsDTO
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof SendSmsDTO
   */
  to: string
}

/**
 *
 * @export
 * @interface SmsTestingDTO
 */
export interface SmsTestingDTO {
  /**
   *
   * @type {string}
   * @memberof SmsTestingDTO
   */
  body: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingDTO
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingDTO
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingDTO
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingDTO
   */
  to: string
}

/**
 *
 * @export
 * @interface SmsTestingSendDTO
 */
export interface SmsTestingSendDTO {
  /**
   *
   * @type {string}
   * @memberof SmsTestingSendDTO
   */
  body: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingSendDTO
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof SmsTestingSendDTO
   */
  to: string
}

/**
 *
 * @export
 * @interface UpdateAlertDTO
 */
export interface UpdateAlertDTO {
  /**
   *
   * @type {any}
   * @memberof UpdateAlertDTO
   */
  externalData?: any
  /**
   *
   * @type {string}
   * @memberof UpdateAlertDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof UpdateAlertDTO
   */
  resolvedByUserId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateAlertDTO
   */
  status: UpdateAlertDTO.StatusEnum
}

/**
 * @export
 * @namespace UpdateAlertDTO
 */
export namespace UpdateAlertDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    RESOLVE = <any>'RESOLVE'
  }
}

/**
 *
 * @export
 * @interface UpdateChannelDTO
 */
export interface UpdateChannelDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateChannelDTO
   */
  name: string
}

/**
 *
 * @export
 * @interface UpdateMessageDTO
 */
export interface UpdateMessageDTO {
  /**
   *
   * @type {Array<any>}
   * @memberof UpdateMessageDTO
   */
  attachments?: Array<any>
  /**
   *
   * @type {any}
   * @memberof UpdateMessageDTO
   */
  externalData?: any
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateMessageDTO
   */
  mentionUserIds?: Array<string>
  /**
   *
   * @type {MetadataDTO}
   * @memberof UpdateMessageDTO
   */
  metadata?: MetadataDTO
  /**
   *
   * @type {string}
   * @memberof UpdateMessageDTO
   */
  senderId: string
  /**
   *
   * @type {RawDraftContentStateDTO}
   * @memberof UpdateMessageDTO
   */
  text: RawDraftContentStateDTO
}

/**
 *
 * @export
 * @interface UserChannelDTO
 */
export interface UserChannelDTO {
  /**
   *
   * @type {string}
   * @memberof UserChannelDTO
   */
  channelId: string
  /**
   *
   * @type {DateISOString}
   * @memberof UserChannelDTO
   */
  lastOpen?: DateISOString
  /**
   *
   * @type {Array<string>}
   * @memberof UserChannelDTO
   */
  mentionMsgIds?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof UserChannelDTO
   */
  mute?: boolean
  /**
   *
   * @type {string}
   * @memberof UserChannelDTO
   */
  userId: string
}

/**
 *
 * @export
 * @interface UserCounterDTO
 */
export interface UserCounterDTO {
  /**
   *
   * @type {number}
   * @memberof UserCounterDTO
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof UserCounterDTO
   */
  id: string
  /**
   *
   * @type {Array<string>}
   * @memberof UserCounterDTO
   */
  mentionMsgIds: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof UserCounterDTO
   */
  mute: boolean
  /**
   *
   * @type {string}
   * @memberof UserCounterDTO
   */
  type: UserCounterDTO.TypeEnum
}

/**
 * @export
 * @namespace UserCounterDTO
 */
export namespace UserCounterDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DDO = <any>'DDO'
  }
}

/**
 *
 * @export
 * @interface UserDataDTO
 */
export interface UserDataDTO {
  /**
   *
   * @type {string}
   * @memberof UserDataDTO
   */
  registrationToken?: string
  /**
   *
   * @type {string}
   * @memberof UserDataDTO
   */
  userId?: string
}

/**
 *
 * @export
 * @interface VendorDTO
 */
export interface VendorDTO {
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  authUserId?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface WebHookMailgunDTO
 */
export interface WebHookMailgunDTO {
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  recipient: string
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  sender: string
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  signature: string
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  strippedText: string
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof WebHookMailgunDTO
   */
  token: string
}

/**
 *
 * @export
 * @interface WebHookTwilioStatusDTO
 */
export interface WebHookTwilioStatusDTO {
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioStatusDTO
   */
  errorCode?: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioStatusDTO
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioStatusDTO
   */
  messageSid: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioStatusDTO
   */
  smsStatus: string
}

/**
 *
 * @export
 * @interface WebHookTwilioSuccessDTO
 */
export interface WebHookTwilioSuccessDTO {
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioSuccessDTO
   */
  body: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioSuccessDTO
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioSuccessDTO
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof WebHookTwilioSuccessDTO
   */
  messageSid: string
  /**
   *
   * @type {number}
   * @memberof WebHookTwilioSuccessDTO
   */
  numMedia?: number
}

/**
 *
 * @export
 * @interface WorkOrderDTO
 */
export interface WorkOrderDTO {
  /**
   *
   * @type {ContainerDTO}
   * @memberof WorkOrderDTO
   */
  container?: ContainerDTO
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  ddoId: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  ddoNumber: string
  /**
   *
   * @type {Array<MoveDTO>}
   * @memberof WorkOrderDTO
   */
  moves?: Array<MoveDTO>
  /**
   *
   * @type {Array<DriverInfoDTO>}
   * @memberof WorkOrderDTO
   */
  selectedDrivers: Array<DriverInfoDTO>
}

/**
 * AlertControllerApi - fetch parameter creator
 * @export
 */
export const AlertControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create an alert
     * @param {CreateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerCreate(body: CreateAlertDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling alertControllerCreate.'
        )
      }
      const localVarPath = `/alert`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateAlertDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of alerts
     * @param {string} [channelId]
     * @param {'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerGetAlerts(
      channelId?: string,
      status?: 'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE',
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/alert`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (channelId !== undefined) {
        localVarQueryParameter['channelId'] = channelId
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the alert
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerRemove(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling alertControllerRemove.')
      }
      const localVarPath = `/alert/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the alert
     * @param {string} id
     * @param {UpdateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerUpdate(id: string, body: UpdateAlertDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling alertControllerUpdate.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling alertControllerUpdate.'
        )
      }
      const localVarPath = `/alert/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateAlertDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AlertControllerApi - functional programming interface
 * @export
 */
export const AlertControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create an alert
     * @param {CreateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerCreate(body: CreateAlertDTO, options?: any): (basePath?: string) => Promise<AlertDTO> {
      const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).alertControllerCreate(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of alerts
     * @param {string} [channelId]
     * @param {'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerGetAlerts(
      channelId?: string,
      status?: 'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE',
      options?: any
    ): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).alertControllerGetAlerts(
        channelId,
        status,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the alert
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerRemove(id: string, options?: any): (basePath?: string) => Promise<AlertDTO> {
      const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).alertControllerRemove(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the alert
     * @param {string} id
     * @param {UpdateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerUpdate(id: string, body: UpdateAlertDTO, options?: any): (basePath?: string) => Promise<AlertDTO> {
      const localVarFetchArgs = AlertControllerApiFetchParamCreator(configuration).alertControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * AlertControllerApi - factory interface
 * @export
 */
export const AlertControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create an alert
     * @param {CreateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerCreate(body: CreateAlertDTO, options?: any) {
      return AlertControllerApiFp(configuration).alertControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary get the list of alerts
     * @param {string} [channelId]
     * @param {'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerGetAlerts(
      channelId?: string,
      status?: 'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE',
      options?: any
    ) {
      return AlertControllerApiFp(configuration).alertControllerGetAlerts(channelId, status, options)(basePath)
    },
    /**
     *
     * @summary delete the alert
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerRemove(id: string, options?: any) {
      return AlertControllerApiFp(configuration).alertControllerRemove(id, options)(basePath)
    },
    /**
     *
     * @summary update the alert
     * @param {string} id
     * @param {UpdateAlertDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alertControllerUpdate(id: string, body: UpdateAlertDTO, options?: any) {
      return AlertControllerApiFp(configuration).alertControllerUpdate(id, body, options)(basePath)
    }
  }
}

/**
 * AlertControllerApi - object-oriented interface
 * @export
 * @class AlertControllerApi
 * @extends {BaseAPI}
 */
export class AlertControllerApi extends BaseAPI {
  /**
   *
   * @summary create an alert
   * @param {CreateAlertDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlertControllerApi
   */
  public alertControllerCreate(body: CreateAlertDTO, options?: any) {
    return AlertControllerApiFp(this.configuration).alertControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary get the list of alerts
   * @param {string} [channelId]
   * @param {'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE'} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlertControllerApi
   */
  public alertControllerGetAlerts(
    channelId?: string,
    status?: 'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE',
    options?: any
  ) {
    return AlertControllerApiFp(this.configuration).alertControllerGetAlerts(channelId, status, options)(this.basePath)
  }

  /**
   *
   * @summary delete the alert
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlertControllerApi
   */
  public alertControllerRemove(id: string, options?: any) {
    return AlertControllerApiFp(this.configuration).alertControllerRemove(id, options)(this.basePath)
  }

  /**
   *
   * @summary update the alert
   * @param {string} id
   * @param {UpdateAlertDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlertControllerApi
   */
  public alertControllerUpdate(id: string, body: UpdateAlertDTO, options?: any) {
    return AlertControllerApiFp(this.configuration).alertControllerUpdate(id, body, options)(this.basePath)
  }
}

/**
 * ChannelControllerApi - fetch parameter creator
 * @export
 */
export const ChannelControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a channel
     * @param {CreateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerCreate(body: CreateChannelDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling channelControllerCreate.'
        )
      }
      const localVarPath = `/channel`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateChannelDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary mute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerMute(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling channelControllerMute.')
      }
      const localVarPath = `/channel/{id}/mute`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary set lastOpen=now() for the channel for user who is sending the request. Mark all messages read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerOpen(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling channelControllerOpen.')
      }
      const localVarPath = `/channel/{id}/open`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the channel
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerRemove(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling channelControllerRemove.'
        )
      }
      const localVarPath = `/channel/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary search channels by IDs
     * @param {SearchChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerSearch(body: SearchChannelDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling channelControllerSearch.'
        )
      }
      const localVarPath = `/channel/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SearchChannelDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary unmute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUnmute(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling channelControllerUnmute.'
        )
      }
      const localVarPath = `/channel/{id}/unmute`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the channel
     * @param {string} id
     * @param {UpdateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUpdate(id: string, body: UpdateChannelDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling channelControllerUpdate.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling channelControllerUpdate.'
        )
      }
      const localVarPath = `/channel/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateChannelDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ChannelControllerApi - functional programming interface
 * @export
 */
export const ChannelControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a channel
     * @param {CreateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerCreate(body: CreateChannelDTO, options?: any): (basePath?: string) => Promise<ChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary mute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerMute(id: string, options?: any): (basePath?: string) => Promise<UserChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerMute(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary set lastOpen=now() for the channel for user who is sending the request. Mark all messages read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerOpen(id: string, options?: any): (basePath?: string) => Promise<UserChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerOpen(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the channel
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerRemove(id: string, options?: any): (basePath?: string) => Promise<ChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerRemove(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary search channels by IDs
     * @param {SearchChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerSearch(body: SearchChannelDTO, options?: any): (basePath?: string) => Promise<Array<ChannelDTO>> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerSearch(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary unmute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUnmute(id: string, options?: any): (basePath?: string) => Promise<UserChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerUnmute(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the channel
     * @param {string} id
     * @param {UpdateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUpdate(
      id: string,
      body: UpdateChannelDTO,
      options?: any
    ): (basePath?: string) => Promise<ChannelDTO> {
      const localVarFetchArgs = ChannelControllerApiFetchParamCreator(configuration).channelControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ChannelControllerApi - factory interface
 * @export
 */
export const ChannelControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a channel
     * @param {CreateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerCreate(body: CreateChannelDTO, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary mute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerMute(id: string, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerMute(id, options)(basePath)
    },
    /**
     *
     * @summary set lastOpen=now() for the channel for user who is sending the request. Mark all messages read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerOpen(id: string, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerOpen(id, options)(basePath)
    },
    /**
     *
     * @summary delete the channel
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerRemove(id: string, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerRemove(id, options)(basePath)
    },
    /**
     *
     * @summary search channels by IDs
     * @param {SearchChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerSearch(body: SearchChannelDTO, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerSearch(body, options)(basePath)
    },
    /**
     *
     * @summary unmute the channel for user who is sending the request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUnmute(id: string, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerUnmute(id, options)(basePath)
    },
    /**
     *
     * @summary update the channel
     * @param {string} id
     * @param {UpdateChannelDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    channelControllerUpdate(id: string, body: UpdateChannelDTO, options?: any) {
      return ChannelControllerApiFp(configuration).channelControllerUpdate(id, body, options)(basePath)
    }
  }
}

/**
 * ChannelControllerApi - object-oriented interface
 * @export
 * @class ChannelControllerApi
 * @extends {BaseAPI}
 */
export class ChannelControllerApi extends BaseAPI {
  /**
   *
   * @summary create a channel
   * @param {CreateChannelDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerCreate(body: CreateChannelDTO, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary mute the channel for user who is sending the request
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerMute(id: string, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerMute(id, options)(this.basePath)
  }

  /**
   *
   * @summary set lastOpen=now() for the channel for user who is sending the request. Mark all messages read
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerOpen(id: string, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerOpen(id, options)(this.basePath)
  }

  /**
   *
   * @summary delete the channel
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerRemove(id: string, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerRemove(id, options)(this.basePath)
  }

  /**
   *
   * @summary search channels by IDs
   * @param {SearchChannelDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerSearch(body: SearchChannelDTO, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerSearch(body, options)(this.basePath)
  }

  /**
   *
   * @summary unmute the channel for user who is sending the request
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerUnmute(id: string, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerUnmute(id, options)(this.basePath)
  }

  /**
   *
   * @summary update the channel
   * @param {string} id
   * @param {UpdateChannelDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChannelControllerApi
   */
  public channelControllerUpdate(id: string, body: UpdateChannelDTO, options?: any) {
    return ChannelControllerApiFp(this.configuration).channelControllerUpdate(id, body, options)(this.basePath)
  }
}

/**
 * CounterControllerApi - fetch parameter creator
 * @export
 */
export const CounterControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * get driver's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get driver's messages count by channel
     * @param {number} pageNo
     * @param {number} pageSize
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetDriverCounters(pageNo: number, pageSize: number, term?: string, options: any = {}): FetchArgs {
      // verify required parameter 'pageNo' is not null or undefined
      if (pageNo === null || pageNo === undefined) {
        throw new RequiredError(
          'pageNo',
          'Required parameter pageNo was null or undefined when calling counterControllerGetDriverCounters.'
        )
      }
      // verify required parameter 'pageSize' is not null or undefined
      if (pageSize === null || pageSize === undefined) {
        throw new RequiredError(
          'pageSize',
          'Required parameter pageSize was null or undefined when calling counterControllerGetDriverCounters.'
        )
      }
      const localVarPath = `/counter/driver`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * get count of messages pinned and alerts in channels for all users
     * @summary get channel's messages count and some meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetGeneralCounters(options: any = {}): FetchArgs {
      const localVarPath = `/counter/general`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * get user's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get user's messages count by channel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetUserCounters(options: any = {}): FetchArgs {
      const localVarPath = `/counter/user`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary search counters by channel IDs
     * @param {SearchCounterDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerSearchCounters(body: SearchCounterDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling counterControllerSearchCounters.'
        )
      }
      const localVarPath = `/counter/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SearchCounterDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CounterControllerApi - functional programming interface
 * @export
 */
export const CounterControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     * get driver's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get driver's messages count by channel
     * @param {number} pageNo
     * @param {number} pageSize
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetDriverCounters(
      pageNo: number,
      pageSize: number,
      term?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DriverCounterDTO>> {
      const localVarFetchArgs = CounterControllerApiFetchParamCreator(configuration).counterControllerGetDriverCounters(
        pageNo,
        pageSize,
        term,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * get count of messages pinned and alerts in channels for all users
     * @summary get channel's messages count and some meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetGeneralCounters(options?: any): (basePath?: string) => Promise<Array<GeneralCounterDTO>> {
      const localVarFetchArgs = CounterControllerApiFetchParamCreator(
        configuration
      ).counterControllerGetGeneralCounters(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * get user's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get user's messages count by channel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetUserCounters(options?: any): (basePath?: string) => Promise<Array<UserCounterDTO>> {
      const localVarFetchArgs = CounterControllerApiFetchParamCreator(configuration).counterControllerGetUserCounters(
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary search counters by channel IDs
     * @param {SearchCounterDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerSearchCounters(
      body: SearchCounterDTO,
      options?: any
    ): (basePath?: string) => Promise<CountersDTO> {
      const localVarFetchArgs = CounterControllerApiFetchParamCreator(configuration).counterControllerSearchCounters(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * CounterControllerApi - factory interface
 * @export
 */
export const CounterControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     * get driver's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get driver's messages count by channel
     * @param {number} pageNo
     * @param {number} pageSize
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetDriverCounters(pageNo: number, pageSize: number, term?: string, options?: any) {
      return CounterControllerApiFp(configuration).counterControllerGetDriverCounters(pageNo, pageSize, term, options)(
        basePath
      )
    },
    /**
     * get count of messages pinned and alerts in channels for all users
     * @summary get channel's messages count and some meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetGeneralCounters(options?: any) {
      return CounterControllerApiFp(configuration).counterControllerGetGeneralCounters(options)(basePath)
    },
    /**
     * get user's messages count by channel since his last visit or 0 if there are no new messages
     * @summary get user's messages count by channel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerGetUserCounters(options?: any) {
      return CounterControllerApiFp(configuration).counterControllerGetUserCounters(options)(basePath)
    },
    /**
     *
     * @summary search counters by channel IDs
     * @param {SearchCounterDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterControllerSearchCounters(body: SearchCounterDTO, options?: any) {
      return CounterControllerApiFp(configuration).counterControllerSearchCounters(body, options)(basePath)
    }
  }
}

/**
 * CounterControllerApi - object-oriented interface
 * @export
 * @class CounterControllerApi
 * @extends {BaseAPI}
 */
export class CounterControllerApi extends BaseAPI {
  /**
   * get driver's messages count by channel since his last visit or 0 if there are no new messages
   * @summary get driver's messages count by channel
   * @param {number} pageNo
   * @param {number} pageSize
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterControllerGetDriverCounters(pageNo: number, pageSize: number, term?: string, options?: any) {
    return CounterControllerApiFp(this.configuration).counterControllerGetDriverCounters(
      pageNo,
      pageSize,
      term,
      options
    )(this.basePath)
  }

  /**
   * get count of messages pinned and alerts in channels for all users
   * @summary get channel's messages count and some meta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterControllerGetGeneralCounters(options?: any) {
    return CounterControllerApiFp(this.configuration).counterControllerGetGeneralCounters(options)(this.basePath)
  }

  /**
   * get user's messages count by channel since his last visit or 0 if there are no new messages
   * @summary get user's messages count by channel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterControllerGetUserCounters(options?: any) {
    return CounterControllerApiFp(this.configuration).counterControllerGetUserCounters(options)(this.basePath)
  }

  /**
   *
   * @summary search counters by channel IDs
   * @param {SearchCounterDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterControllerSearchCounters(body: SearchCounterDTO, options?: any) {
    return CounterControllerApiFp(this.configuration).counterControllerSearchCounters(body, options)(this.basePath)
  }
}

/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get a token for firebase API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetFirebaseToken(options: any = {}): FetchArgs {
      const localVarPath = `/user/fb-token`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options: any = {}): FetchArgs {
      const localVarPath = `/healthcheck`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerIndex(options: any = {}): FetchArgs {
      const localVarPath = `/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update user data
     * @param {UserDataDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerUserData(body: UserDataDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling appControllerUserData.'
        )
      }
      const localVarPath = `/user/data`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UserDataDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options: any = {}): FetchArgs {
      const localVarPath = `/prometheus`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get a token for firebase API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetFirebaseToken(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerGetFirebaseToken(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerHealthCheck(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerIndex(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerIndex(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update user data
     * @param {UserDataDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerUserData(body: UserDataDTO, options?: any): (basePath?: string) => Promise<UserDataDTO> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerUserData(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).promControllerIndex(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary get a token for firebase API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetFirebaseToken(options?: any) {
      return DefaultApiFp(configuration).appControllerGetFirebaseToken(options)(basePath)
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any) {
      return DefaultApiFp(configuration).appControllerHealthCheck(options)(basePath)
    },
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerIndex(options?: any) {
      return DefaultApiFp(configuration).appControllerIndex(options)(basePath)
    },
    /**
     *
     * @summary update user data
     * @param {UserDataDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerUserData(body: UserDataDTO, options?: any) {
      return DefaultApiFp(configuration).appControllerUserData(body, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any) {
      return DefaultApiFp(configuration).promControllerIndex(options)(basePath)
    }
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary get a token for firebase API
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetFirebaseToken(options?: any) {
    return DefaultApiFp(this.configuration).appControllerGetFirebaseToken(options)(this.basePath)
  }

  /**
   *
   * @summary health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerHealthCheck(options?: any) {
    return DefaultApiFp(this.configuration).appControllerHealthCheck(options)(this.basePath)
  }

  /**
   *
   * @summary health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerIndex(options?: any) {
    return DefaultApiFp(this.configuration).appControllerIndex(options)(this.basePath)
  }

  /**
   *
   * @summary update user data
   * @param {UserDataDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerUserData(body: UserDataDTO, options?: any) {
    return DefaultApiFp(this.configuration).appControllerUserData(body, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public promControllerIndex(options?: any) {
    return DefaultApiFp(this.configuration).promControllerIndex(options)(this.basePath)
  }
}

/**
 * DocumentControllerApi - fetch parameter creator
 * @export
 */
export const DocumentControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a document
     * @param {CreateDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCreate(body: CreateDocumentDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling documentControllerCreate.'
        )
      }
      const localVarPath = `/document`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateDocumentDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of documents for the channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments(channelId: string, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling documentControllerGetDocuments.'
        )
      }
      const localVarPath = `/document`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (channelId !== undefined) {
        localVarQueryParameter['channelId'] = channelId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary returns the last by createdAt document for the requested driver activity
     * @param {SearchDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearchDocument(body: SearchDocumentDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling documentControllerSearchDocument.'
        )
      }
      const localVarPath = `/document/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SearchDocumentDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a document
     * @param {CreateDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCreate(body: CreateDocumentDTO, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).documentControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of documents for the channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments(
      channelId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).documentControllerGetDocuments(
        channelId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary returns the last by createdAt document for the requested driver activity
     * @param {SearchDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearchDocument(
      body: SearchDocumentDTO,
      options?: any
    ): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).documentControllerSearchDocument(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a document
     * @param {CreateDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCreate(body: CreateDocumentDTO, options?: any) {
      return DocumentControllerApiFp(configuration).documentControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary get the list of documents for the channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments(channelId: string, options?: any) {
      return DocumentControllerApiFp(configuration).documentControllerGetDocuments(channelId, options)(basePath)
    },
    /**
     *
     * @summary returns the last by createdAt document for the requested driver activity
     * @param {SearchDocumentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearchDocument(body: SearchDocumentDTO, options?: any) {
      return DocumentControllerApiFp(configuration).documentControllerSearchDocument(body, options)(basePath)
    }
  }
}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
  /**
   *
   * @summary create a document
   * @param {CreateDocumentDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public documentControllerCreate(body: CreateDocumentDTO, options?: any) {
    return DocumentControllerApiFp(this.configuration).documentControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary get the list of documents for the channel
   * @param {string} channelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public documentControllerGetDocuments(channelId: string, options?: any) {
    return DocumentControllerApiFp(this.configuration).documentControllerGetDocuments(channelId, options)(this.basePath)
  }

  /**
   *
   * @summary returns the last by createdAt document for the requested driver activity
   * @param {SearchDocumentDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public documentControllerSearchDocument(body: SearchDocumentDTO, options?: any) {
    return DocumentControllerApiFp(this.configuration).documentControllerSearchDocument(body, options)(this.basePath)
  }
}

/**
 * InviteControllerApi - fetch parameter creator
 * @export
 */
export const InviteControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Create and sent SMS message with an invitation to driver
     * @summary send an SMS
     * @param {DriverInviteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerInvite(body: DriverInviteDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling inviteControllerInvite.'
        )
      }
      const localVarPath = `/invite/sms`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverInviteDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Save new codes to database
     * @summary Upload codes
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerUploadCodes(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling inviteControllerUploadCodes.'
        )
      }
      const localVarPath = `/invite/upload-codes`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * InviteControllerApi - functional programming interface
 * @export
 */
export const InviteControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Create and sent SMS message with an invitation to driver
     * @summary send an SMS
     * @param {DriverInviteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerInvite(body: DriverInviteDTO, options?: any): (basePath?: string) => Promise<InviteDTO> {
      const localVarFetchArgs = InviteControllerApiFetchParamCreator(configuration).inviteControllerInvite(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Save new codes to database
     * @summary Upload codes
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerUploadCodes(body: Array<string>, options?: any): (basePath?: string) => Promise<number> {
      const localVarFetchArgs = InviteControllerApiFetchParamCreator(configuration).inviteControllerUploadCodes(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * InviteControllerApi - factory interface
 * @export
 */
export const InviteControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Create and sent SMS message with an invitation to driver
     * @summary send an SMS
     * @param {DriverInviteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerInvite(body: DriverInviteDTO, options?: any) {
      return InviteControllerApiFp(configuration).inviteControllerInvite(body, options)(basePath)
    },
    /**
     * Save new codes to database
     * @summary Upload codes
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteControllerUploadCodes(body: Array<string>, options?: any) {
      return InviteControllerApiFp(configuration).inviteControllerUploadCodes(body, options)(basePath)
    }
  }
}

/**
 * InviteControllerApi - object-oriented interface
 * @export
 * @class InviteControllerApi
 * @extends {BaseAPI}
 */
export class InviteControllerApi extends BaseAPI {
  /**
   * Create and sent SMS message with an invitation to driver
   * @summary send an SMS
   * @param {DriverInviteDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteControllerApi
   */
  public inviteControllerInvite(body: DriverInviteDTO, options?: any) {
    return InviteControllerApiFp(this.configuration).inviteControllerInvite(body, options)(this.basePath)
  }

  /**
   * Save new codes to database
   * @summary Upload codes
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteControllerApi
   */
  public inviteControllerUploadCodes(body: Array<string>, options?: any) {
    return InviteControllerApiFp(this.configuration).inviteControllerUploadCodes(body, options)(this.basePath)
  }
}

/**
 * MessageControllerApi - fetch parameter creator
 * @export
 */
export const MessageControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a message
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerCreate(body: CreateMessageDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling messageControllerCreate.'
        )
      }
      const localVarPath = `/message`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateMessageDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get all unread messages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetAllUnreadMessages(options: any = {}): FetchArgs {
      const localVarPath = `/message/all-unread`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of messages for the channel
     * @param {string} channelId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetMessages(channelId: string, limit: number, page: number, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling messageControllerGetMessages.'
        )
      }
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling messageControllerGetMessages.'
        )
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError(
          'page',
          'Required parameter page was null or undefined when calling messageControllerGetMessages.'
        )
      }
      const localVarPath = `/message`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (channelId !== undefined) {
        localVarQueryParameter['channelId'] = channelId
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of unhandled driver sms
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetUnhandledDriverSms(limit: number, page: number, options: any = {}): FetchArgs {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling messageControllerGetUnhandledDriverSms.'
        )
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError(
          'page',
          'Required parameter page was null or undefined when calling messageControllerGetUnhandledDriverSms.'
        )
      }
      const localVarPath = `/message/unhandled-driver-sms`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary mark all messages as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerMarkAllRead(options: any = {}): FetchArgs {
      const localVarPath = `/message/mark-all-read`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary pin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerPin(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling messageControllerPin.')
      }
      const localVarPath = `/message/{id}/pin`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary mark message as read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRead(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling messageControllerRead.')
      }
      const localVarPath = `/message/{id}/read`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRemove(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling messageControllerRemove.'
        )
      }
      const localVarPath = `/message/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary unpin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnpin(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling messageControllerUnpin.'
        )
      }
      const localVarPath = `/message/{id}/unpin`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary mark message as unread
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnread(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling messageControllerUnread.'
        )
      }
      const localVarPath = `/message/{id}/unread`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the message
     * @param {string} id
     * @param {UpdateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUpdate(id: string, body: UpdateMessageDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling messageControllerUpdate.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling messageControllerUpdate.'
        )
      }
      const localVarPath = `/message/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateMessageDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * MessageControllerApi - functional programming interface
 * @export
 */
export const MessageControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a message
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerCreate(body: CreateMessageDTO, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get all unread messages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetAllUnreadMessages(options?: any): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(
        configuration
      ).messageControllerGetAllUnreadMessages(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of messages for the channel
     * @param {string} channelId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetMessages(
      channelId: string,
      limit: number,
      page: number,
      options?: any
    ): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerGetMessages(
        channelId,
        limit,
        page,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of unhandled driver sms
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetUnhandledDriverSms(
      limit: number,
      page: number,
      options?: any
    ): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(
        configuration
      ).messageControllerGetUnhandledDriverSms(limit, page, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary mark all messages as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerMarkAllRead(options?: any): (basePath?: string) => Promise<Array<UserCounterDTO>> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerMarkAllRead(
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary pin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerPin(id: string, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerPin(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary mark message as read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRead(id: string, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerRead(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRemove(id: string, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerRemove(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary unpin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnpin(id: string, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerUnpin(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary mark message as unread
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnread(id: string, options?: any): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerUnread(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the message
     * @param {string} id
     * @param {UpdateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUpdate(
      id: string,
      body: UpdateMessageDTO,
      options?: any
    ): (basePath?: string) => Promise<MessageDTO> {
      const localVarFetchArgs = MessageControllerApiFetchParamCreator(configuration).messageControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * MessageControllerApi - factory interface
 * @export
 */
export const MessageControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a message
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerCreate(body: CreateMessageDTO, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary get all unread messages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetAllUnreadMessages(options?: any) {
      return MessageControllerApiFp(configuration).messageControllerGetAllUnreadMessages(options)(basePath)
    },
    /**
     *
     * @summary get the list of messages for the channel
     * @param {string} channelId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetMessages(channelId: string, limit: number, page: number, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerGetMessages(channelId, limit, page, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the list of unhandled driver sms
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerGetUnhandledDriverSms(limit: number, page: number, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerGetUnhandledDriverSms(limit, page, options)(
        basePath
      )
    },
    /**
     *
     * @summary mark all messages as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerMarkAllRead(options?: any) {
      return MessageControllerApiFp(configuration).messageControllerMarkAllRead(options)(basePath)
    },
    /**
     *
     * @summary pin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerPin(id: string, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerPin(id, options)(basePath)
    },
    /**
     *
     * @summary mark message as read
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRead(id: string, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerRead(id, options)(basePath)
    },
    /**
     *
     * @summary delete the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerRemove(id: string, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerRemove(id, options)(basePath)
    },
    /**
     *
     * @summary unpin the message
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnpin(id: string, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerUnpin(id, options)(basePath)
    },
    /**
     *
     * @summary mark message as unread
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUnread(id: string, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerUnread(id, options)(basePath)
    },
    /**
     *
     * @summary update the message
     * @param {string} id
     * @param {UpdateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageControllerUpdate(id: string, body: UpdateMessageDTO, options?: any) {
      return MessageControllerApiFp(configuration).messageControllerUpdate(id, body, options)(basePath)
    }
  }
}

/**
 * MessageControllerApi - object-oriented interface
 * @export
 * @class MessageControllerApi
 * @extends {BaseAPI}
 */
export class MessageControllerApi extends BaseAPI {
  /**
   *
   * @summary create a message
   * @param {CreateMessageDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerCreate(body: CreateMessageDTO, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary get all unread messages
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerGetAllUnreadMessages(options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerGetAllUnreadMessages(options)(this.basePath)
  }

  /**
   *
   * @summary get the list of messages for the channel
   * @param {string} channelId
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerGetMessages(channelId: string, limit: number, page: number, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerGetMessages(channelId, limit, page, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get the list of unhandled driver sms
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerGetUnhandledDriverSms(limit: number, page: number, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerGetUnhandledDriverSms(limit, page, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary mark all messages as read
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerMarkAllRead(options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerMarkAllRead(options)(this.basePath)
  }

  /**
   *
   * @summary pin the message
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerPin(id: string, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerPin(id, options)(this.basePath)
  }

  /**
   *
   * @summary mark message as read
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerRead(id: string, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerRead(id, options)(this.basePath)
  }

  /**
   *
   * @summary delete the message
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerRemove(id: string, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerRemove(id, options)(this.basePath)
  }

  /**
   *
   * @summary unpin the message
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerUnpin(id: string, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerUnpin(id, options)(this.basePath)
  }

  /**
   *
   * @summary mark message as unread
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerUnread(id: string, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerUnread(id, options)(this.basePath)
  }

  /**
   *
   * @summary update the message
   * @param {string} id
   * @param {UpdateMessageDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessageControllerApi
   */
  public messageControllerUpdate(id: string, body: UpdateMessageDTO, options?: any) {
    return MessageControllerApiFp(this.configuration).messageControllerUpdate(id, body, options)(this.basePath)
  }
}

/**
 * NotificationControllerApi - fetch parameter creator
 * @export
 */
export const NotificationControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary add a reply to the notification
     * @param {string} id
     * @param {AnswersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerAddReply(id: string, body: AnswersDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling notificationControllerAddReply.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling notificationControllerAddReply.'
        )
      }
      const localVarPath = `/notification/{id}/reply`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'AnswersDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary batch create replies
     * @param {BatchCreateRepliesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerBatchReplies(body: BatchCreateRepliesDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling notificationControllerBatchReplies.'
        )
      }
      const localVarPath = `/notification/batch-reply`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BatchCreateRepliesDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary complete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerComplete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling notificationControllerComplete.'
        )
      }
      const localVarPath = `/notification/{id}/complete`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary create a notification
     * @param {CreateNotificationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerCreate(body: CreateNotificationDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling notificationControllerCreate.'
        )
      }
      const localVarPath = `/notification`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateNotificationDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerDelete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling notificationControllerDelete.'
        )
      }
      const localVarPath = `/notification/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary search notifications
     * @param {GetNotificationListDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAll(body: GetNotificationListDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling notificationControllerFindAll.'
        )
      }
      const localVarPath = `/notification/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'GetNotificationListDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary search notifications for a driver
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAllForDriver(limit: number, page: number, options: any = {}): FetchArgs {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling notificationControllerFindAllForDriver.'
        )
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError(
          'page',
          'Required parameter page was null or undefined when calling notificationControllerFindAllForDriver.'
        )
      }
      const localVarPath = `/notification/search/driver`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the notification by id for driver
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling notificationControllerFindById.'
        )
      }
      const localVarPath = `/notification/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get latest notification with type Acknowledgment, Poll, ResignDocument for a driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetLatestRequiredNotification(options: any = {}): FetchArgs {
      const localVarPath = `/notification/latest/driver`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get replies of the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetReplies(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling notificationControllerGetReplies.'
        )
      }
      const localVarPath = `/notification/{id}/reply`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary push latest notification
     * @param {string} recipientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerPushNotification(recipientId: string, options: any = {}): FetchArgs {
      // verify required parameter 'recipientId' is not null or undefined
      if (recipientId === null || recipientId === undefined) {
        throw new RequiredError(
          'recipientId',
          'Required parameter recipientId was null or undefined when calling notificationControllerPushNotification.'
        )
      }
      const localVarPath = `/notification/push/{recipientId}`.replace(
        `{${'recipientId'}}`,
        encodeURIComponent(String(recipientId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary add a reply to the notification
     * @param {string} id
     * @param {AnswersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerAddReply(
      id: string,
      body: AnswersDTO,
      options?: any
    ): (basePath?: string) => Promise<NotificationReplyDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerAddReply(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary batch create replies
     * @param {BatchCreateRepliesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerBatchReplies(
      body: BatchCreateRepliesDTO,
      options?: any
    ): (basePath?: string) => Promise<Array<NotificationReplyDTO>> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerBatchReplies(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary complete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerComplete(id: string, options?: any): (basePath?: string) => Promise<NotificationDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerComplete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary create a notification
     * @param {CreateNotificationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerCreate(
      body: CreateNotificationDTO,
      options?: any
    ): (basePath?: string) => Promise<NotificationDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(configuration).notificationControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerDelete(id: string, options?: any): (basePath?: string) => Promise<NotificationDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(configuration).notificationControllerDelete(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary search notifications
     * @param {GetNotificationListDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAll(
      body: GetNotificationListDTO,
      options?: any
    ): (basePath?: string) => Promise<Array<NotificationDTO>> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(configuration).notificationControllerFindAll(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary search notifications for a driver
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAllForDriver(
      limit: number,
      page: number,
      options?: any
    ): (basePath?: string) => Promise<Array<DriverNotificationDTO>> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerFindAllForDriver(limit, page, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the notification by id for driver
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindById(id: string, options?: any): (basePath?: string) => Promise<DriverNotificationDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerFindById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get latest notification with type Acknowledgment, Poll, ResignDocument for a driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetLatestRequiredNotification(
      options?: any
    ): (basePath?: string) => Promise<DriverNotificationDTO> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerGetLatestRequiredNotification(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get replies of the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetReplies(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerGetReplies(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary push latest notification
     * @param {string} recipientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerPushNotification(
      recipientId: string,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationControllerApiFetchParamCreator(
        configuration
      ).notificationControllerPushNotification(recipientId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary add a reply to the notification
     * @param {string} id
     * @param {AnswersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerAddReply(id: string, body: AnswersDTO, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerAddReply(id, body, options)(basePath)
    },
    /**
     *
     * @summary batch create replies
     * @param {BatchCreateRepliesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerBatchReplies(body: BatchCreateRepliesDTO, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerBatchReplies(body, options)(basePath)
    },
    /**
     *
     * @summary complete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerComplete(id: string, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerComplete(id, options)(basePath)
    },
    /**
     *
     * @summary create a notification
     * @param {CreateNotificationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerCreate(body: CreateNotificationDTO, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary delete the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerDelete(id: string, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerDelete(id, options)(basePath)
    },
    /**
     *
     * @summary search notifications
     * @param {GetNotificationListDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAll(body: GetNotificationListDTO, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerFindAll(body, options)(basePath)
    },
    /**
     *
     * @summary search notifications for a driver
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindAllForDriver(limit: number, page: number, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerFindAllForDriver(limit, page, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the notification by id for driver
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerFindById(id: string, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerFindById(id, options)(basePath)
    },
    /**
     *
     * @summary get latest notification with type Acknowledgment, Poll, ResignDocument for a driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetLatestRequiredNotification(options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerGetLatestRequiredNotification(options)(
        basePath
      )
    },
    /**
     *
     * @summary get replies of the notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerGetReplies(id: string, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerGetReplies(id, options)(basePath)
    },
    /**
     *
     * @summary push latest notification
     * @param {string} recipientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerPushNotification(recipientId: string, options?: any) {
      return NotificationControllerApiFp(configuration).notificationControllerPushNotification(recipientId, options)(
        basePath
      )
    }
  }
}

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
  /**
   *
   * @summary add a reply to the notification
   * @param {string} id
   * @param {AnswersDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerAddReply(id: string, body: AnswersDTO, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerAddReply(id, body, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary batch create replies
   * @param {BatchCreateRepliesDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerBatchReplies(body: BatchCreateRepliesDTO, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerBatchReplies(body, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary complete the notification
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerComplete(id: string, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerComplete(id, options)(this.basePath)
  }

  /**
   *
   * @summary create a notification
   * @param {CreateNotificationDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerCreate(body: CreateNotificationDTO, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary delete the notification
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerDelete(id: string, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerDelete(id, options)(this.basePath)
  }

  /**
   *
   * @summary search notifications
   * @param {GetNotificationListDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerFindAll(body: GetNotificationListDTO, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerFindAll(body, options)(this.basePath)
  }

  /**
   *
   * @summary search notifications for a driver
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerFindAllForDriver(limit: number, page: number, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerFindAllForDriver(limit, page, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get the notification by id for driver
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerFindById(id: string, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerFindById(id, options)(this.basePath)
  }

  /**
   *
   * @summary get latest notification with type Acknowledgment, Poll, ResignDocument for a driver
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerGetLatestRequiredNotification(options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerGetLatestRequiredNotification(options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get replies of the notification
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerGetReplies(id: string, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerGetReplies(id, options)(this.basePath)
  }

  /**
   *
   * @summary push latest notification
   * @param {string} recipientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public notificationControllerPushNotification(recipientId: string, options?: any) {
    return NotificationControllerApiFp(this.configuration).notificationControllerPushNotification(recipientId, options)(
      this.basePath
    )
  }
}

/**
 * PhoneNumberControllerApi - fetch parameter creator
 * @export
 */
export const PhoneNumberControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary detach phone number for the channel if it exists
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerDetachPhoneNumber(channelId: string, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling phoneNumberControllerDetachPhoneNumber.'
        )
      }
      const localVarPath = `/phone-number/{channelId}/detach`.replace(
        `{${'channelId'}}`,
        encodeURIComponent(String(channelId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Force buy and attach phone number for channelId
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerForceBuyAndAttachNumber(channelId: string, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling phoneNumberControllerForceBuyAndAttachNumber.'
        )
      }
      const localVarPath = `/phone-number/{channelId}/forceBuyAndAttachNumber`.replace(
        `{${'channelId'}}`,
        encodeURIComponent(String(channelId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get phone number for a channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberByChannelId(channelId: string, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling phoneNumberControllerGetPhoneNumberByChannelId.'
        )
      }
      const localVarPath = `/phone-number/{channelId}`.replace(
        `{${'channelId'}}`,
        encodeURIComponent(String(channelId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get statistics of using phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberStatistics(options: any = {}): FetchArgs {
      const localVarPath = `/phone-number/stats/data`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get statistics page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetStatsPage(options: any = {}): FetchArgs {
      const localVarPath = `/phone-number/stats`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary remove phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerRemovePhoneNumbers(options: any = {}): FetchArgs {
      const localVarPath = `/phone-number/stats/remove`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Set Messaging Service SID for the phone number
     * @param {string} phoneSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerSetMessagingServiceSid(phoneSid: string, options: any = {}): FetchArgs {
      // verify required parameter 'phoneSid' is not null or undefined
      if (phoneSid === null || phoneSid === undefined) {
        throw new RequiredError(
          'phoneSid',
          'Required parameter phoneSid was null or undefined when calling phoneNumberControllerSetMessagingServiceSid.'
        )
      }
      const localVarPath = `/phone-number/{phoneSid}/setMessagingService`.replace(
        `{${'phoneSid'}}`,
        encodeURIComponent(String(phoneSid))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PhoneNumberControllerApi - functional programming interface
 * @export
 */
export const PhoneNumberControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary detach phone number for the channel if it exists
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerDetachPhoneNumber(
      channelId: string,
      options?: any
    ): (basePath?: string) => Promise<PhoneNumberDTO> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerDetachPhoneNumber(channelId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Force buy and attach phone number for channelId
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerForceBuyAndAttachNumber(
      channelId: string,
      options?: any
    ): (basePath?: string) => Promise<PhoneNumberInstance> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerForceBuyAndAttachNumber(channelId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get phone number for a channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberByChannelId(
      channelId: string,
      options?: any
    ): (basePath?: string) => Promise<PhoneNumberDTO> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerGetPhoneNumberByChannelId(channelId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get statistics of using phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberStatistics(options?: any): (basePath?: string) => Promise<PhoneNumberStatDTO> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerGetPhoneNumberStatistics(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get statistics page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetStatsPage(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerGetStatsPage(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary remove phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerRemovePhoneNumbers(options?: any): (basePath?: string) => Promise<RemovePhoneNumbersDTO> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerRemovePhoneNumbers(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Set Messaging Service SID for the phone number
     * @param {string} phoneSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerSetMessagingServiceSid(
      phoneSid: string,
      options?: any
    ): (basePath?: string) => Promise<PhoneNumberInstance> {
      const localVarFetchArgs = PhoneNumberControllerApiFetchParamCreator(
        configuration
      ).phoneNumberControllerSetMessagingServiceSid(phoneSid, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * PhoneNumberControllerApi - factory interface
 * @export
 */
export const PhoneNumberControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary detach phone number for the channel if it exists
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerDetachPhoneNumber(channelId: string, options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerDetachPhoneNumber(channelId, options)(
        basePath
      )
    },
    /**
     *
     * @summary Force buy and attach phone number for channelId
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerForceBuyAndAttachNumber(channelId: string, options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerForceBuyAndAttachNumber(channelId, options)(
        basePath
      )
    },
    /**
     *
     * @summary get phone number for a channel
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberByChannelId(channelId: string, options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerGetPhoneNumberByChannelId(
        channelId,
        options
      )(basePath)
    },
    /**
     *
     * @summary get statistics of using phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetPhoneNumberStatistics(options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerGetPhoneNumberStatistics(options)(basePath)
    },
    /**
     *
     * @summary get statistics page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerGetStatsPage(options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerGetStatsPage(options)(basePath)
    },
    /**
     *
     * @summary remove phone numbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerRemovePhoneNumbers(options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerRemovePhoneNumbers(options)(basePath)
    },
    /**
     *
     * @summary Set Messaging Service SID for the phone number
     * @param {string} phoneSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    phoneNumberControllerSetMessagingServiceSid(phoneSid: string, options?: any) {
      return PhoneNumberControllerApiFp(configuration).phoneNumberControllerSetMessagingServiceSid(phoneSid, options)(
        basePath
      )
    }
  }
}

/**
 * PhoneNumberControllerApi - object-oriented interface
 * @export
 * @class PhoneNumberControllerApi
 * @extends {BaseAPI}
 */
export class PhoneNumberControllerApi extends BaseAPI {
  /**
   *
   * @summary detach phone number for the channel if it exists
   * @param {string} channelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerDetachPhoneNumber(channelId: string, options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerDetachPhoneNumber(channelId, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary Force buy and attach phone number for channelId
   * @param {string} channelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerForceBuyAndAttachNumber(channelId: string, options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerForceBuyAndAttachNumber(
      channelId,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary get phone number for a channel
   * @param {string} channelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerGetPhoneNumberByChannelId(channelId: string, options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerGetPhoneNumberByChannelId(
      channelId,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary get statistics of using phone numbers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerGetPhoneNumberStatistics(options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerGetPhoneNumberStatistics(options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get statistics page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerGetStatsPage(options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerGetStatsPage(options)(this.basePath)
  }

  /**
   *
   * @summary remove phone numbers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerRemovePhoneNumbers(options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerRemovePhoneNumbers(options)(
      this.basePath
    )
  }

  /**
   *
   * @summary Set Messaging Service SID for the phone number
   * @param {string} phoneSid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhoneNumberControllerApi
   */
  public phoneNumberControllerSetMessagingServiceSid(phoneSid: string, options?: any) {
    return PhoneNumberControllerApiFp(this.configuration).phoneNumberControllerSetMessagingServiceSid(
      phoneSid,
      options
    )(this.basePath)
  }
}

/**
 * SmsControllerApi - fetch parameter creator
 * @export
 */
export const SmsControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Get delivered messages between two phone numbers. Both number should be registered in Twilio.
     * @summary get delivered messages
     * @param {string} firstNumber
     * @param {string} secondNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetList(firstNumber: string, secondNumber: string, options: any = {}): FetchArgs {
      // verify required parameter 'firstNumber' is not null or undefined
      if (firstNumber === null || firstNumber === undefined) {
        throw new RequiredError(
          'firstNumber',
          'Required parameter firstNumber was null or undefined when calling smsControllerGetList.'
        )
      }
      // verify required parameter 'secondNumber' is not null or undefined
      if (secondNumber === null || secondNumber === undefined) {
        throw new RequiredError(
          'secondNumber',
          'Required parameter secondNumber was null or undefined when calling smsControllerGetList.'
        )
      }
      const localVarPath = `/sms/test/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (firstNumber !== undefined) {
        localVarQueryParameter['firstNumber'] = firstNumber
      }

      if (secondNumber !== undefined) {
        localVarQueryParameter['secondNumber'] = secondNumber
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * You can send messages between phone numbers in Twilio and see an entire message history
     * @summary get an HTML page for sms testing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetTestingPage(options: any = {}): FetchArgs {
      const localVarPath = `/sms/test`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Unconditionally send SMS with message
     * @summary send message and sms
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendMessage(body: CreateMessageDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling smsControllerSendMessage.'
        )
      }
      const localVarPath = `/sms`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateMessageDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Send SMS without any specific logic
     * @summary send sms without any specific logic
     * @param {SendSmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendPlainSms(body: SendSmsDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling smsControllerSendPlainSms.'
        )
      }
      const localVarPath = `/sms/plain-send`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SendSmsDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Send an SMS from one phone number to another
     * @summary send an SMS
     * @param {SmsTestingSendDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMessage(body: SmsTestingSendDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling smsControllerSendTestMessage.'
        )
      }
      const localVarPath = `/sms/test/send`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SmsTestingSendDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Send an MMS from one phone number to another
     * @summary send an MMS
     * @param {SendMmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMms(body: SendMmsDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling smsControllerSendTestMms.'
        )
      }
      const localVarPath = `/sms/test/send_mms`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SendMmsDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * SmsControllerApi - functional programming interface
 * @export
 */
export const SmsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get delivered messages between two phone numbers. Both number should be registered in Twilio.
     * @summary get delivered messages
     * @param {string} firstNumber
     * @param {string} secondNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetList(
      firstNumber: string,
      secondNumber: string,
      options?: any
    ): (basePath?: string) => Promise<Array<SmsTestingDTO>> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerGetList(
        firstNumber,
        secondNumber,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * You can send messages between phone numbers in Twilio and see an entire message history
     * @summary get an HTML page for sms testing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetTestingPage(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerGetTestingPage(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Unconditionally send SMS with message
     * @summary send message and sms
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendMessage(body: CreateMessageDTO, options?: any): (basePath?: string) => Promise<CreateMessageDTO> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerSendMessage(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Send SMS without any specific logic
     * @summary send sms without any specific logic
     * @param {SendSmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendPlainSms(body: SendSmsDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerSendPlainSms(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Send an SMS from one phone number to another
     * @summary send an SMS
     * @param {SmsTestingSendDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMessage(
      body: SmsTestingSendDTO,
      options?: any
    ): (basePath?: string) => Promise<SmsTestingDTO> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerSendTestMessage(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Send an MMS from one phone number to another
     * @summary send an MMS
     * @param {SendMmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMms(body: SendMmsDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SmsControllerApiFetchParamCreator(configuration).smsControllerSendTestMms(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * SmsControllerApi - factory interface
 * @export
 */
export const SmsControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Get delivered messages between two phone numbers. Both number should be registered in Twilio.
     * @summary get delivered messages
     * @param {string} firstNumber
     * @param {string} secondNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetList(firstNumber: string, secondNumber: string, options?: any) {
      return SmsControllerApiFp(configuration).smsControllerGetList(firstNumber, secondNumber, options)(basePath)
    },
    /**
     * You can send messages between phone numbers in Twilio and see an entire message history
     * @summary get an HTML page for sms testing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerGetTestingPage(options?: any) {
      return SmsControllerApiFp(configuration).smsControllerGetTestingPage(options)(basePath)
    },
    /**
     * Unconditionally send SMS with message
     * @summary send message and sms
     * @param {CreateMessageDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendMessage(body: CreateMessageDTO, options?: any) {
      return SmsControllerApiFp(configuration).smsControllerSendMessage(body, options)(basePath)
    },
    /**
     * Send SMS without any specific logic
     * @summary send sms without any specific logic
     * @param {SendSmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendPlainSms(body: SendSmsDTO, options?: any) {
      return SmsControllerApiFp(configuration).smsControllerSendPlainSms(body, options)(basePath)
    },
    /**
     * Send an SMS from one phone number to another
     * @summary send an SMS
     * @param {SmsTestingSendDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMessage(body: SmsTestingSendDTO, options?: any) {
      return SmsControllerApiFp(configuration).smsControllerSendTestMessage(body, options)(basePath)
    },
    /**
     * Send an MMS from one phone number to another
     * @summary send an MMS
     * @param {SendMmsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsControllerSendTestMms(body: SendMmsDTO, options?: any) {
      return SmsControllerApiFp(configuration).smsControllerSendTestMms(body, options)(basePath)
    }
  }
}

/**
 * SmsControllerApi - object-oriented interface
 * @export
 * @class SmsControllerApi
 * @extends {BaseAPI}
 */
export class SmsControllerApi extends BaseAPI {
  /**
   * Get delivered messages between two phone numbers. Both number should be registered in Twilio.
   * @summary get delivered messages
   * @param {string} firstNumber
   * @param {string} secondNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerGetList(firstNumber: string, secondNumber: string, options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerGetList(firstNumber, secondNumber, options)(
      this.basePath
    )
  }

  /**
   * You can send messages between phone numbers in Twilio and see an entire message history
   * @summary get an HTML page for sms testing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerGetTestingPage(options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerGetTestingPage(options)(this.basePath)
  }

  /**
   * Unconditionally send SMS with message
   * @summary send message and sms
   * @param {CreateMessageDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerSendMessage(body: CreateMessageDTO, options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerSendMessage(body, options)(this.basePath)
  }

  /**
   * Send SMS without any specific logic
   * @summary send sms without any specific logic
   * @param {SendSmsDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerSendPlainSms(body: SendSmsDTO, options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerSendPlainSms(body, options)(this.basePath)
  }

  /**
   * Send an SMS from one phone number to another
   * @summary send an SMS
   * @param {SmsTestingSendDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerSendTestMessage(body: SmsTestingSendDTO, options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerSendTestMessage(body, options)(this.basePath)
  }

  /**
   * Send an MMS from one phone number to another
   * @summary send an MMS
   * @param {SendMmsDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsControllerApi
   */
  public smsControllerSendTestMms(body: SendMmsDTO, options?: any) {
    return SmsControllerApiFp(this.configuration).smsControllerSendTestMms(body, options)(this.basePath)
  }
}

/**
 * WebhookControllerApi - fetch parameter creator
 * @export
 */
export const WebhookControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Forward email text to user's chat
     * @summary webhook for mailgun
     * @param {string} recipient
     * @param {string} sender
     * @param {string} strippedText
     * @param {string} timestamp
     * @param {string} token
     * @param {string} signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleMailgunWebhook(
      recipient: string,
      sender: string,
      strippedText: string,
      timestamp: string,
      token: string,
      signature: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'recipient' is not null or undefined
      if (recipient === null || recipient === undefined) {
        throw new RequiredError(
          'recipient',
          'Required parameter recipient was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      // verify required parameter 'sender' is not null or undefined
      if (sender === null || sender === undefined) {
        throw new RequiredError(
          'sender',
          'Required parameter sender was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      // verify required parameter 'strippedText' is not null or undefined
      if (strippedText === null || strippedText === undefined) {
        throw new RequiredError(
          'strippedText',
          'Required parameter strippedText was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      // verify required parameter 'timestamp' is not null or undefined
      if (timestamp === null || timestamp === undefined) {
        throw new RequiredError(
          'timestamp',
          'Required parameter timestamp was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      // verify required parameter 'signature' is not null or undefined
      if (signature === null || signature === undefined) {
        throw new RequiredError(
          'signature',
          'Required parameter signature was null or undefined when calling webHookControllerHandleMailgunWebhook.'
        )
      }
      const localVarPath = `/webhook/mailgun`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (recipient !== undefined) {
        localVarFormParams.set('recipient', recipient as any)
      }

      if (sender !== undefined) {
        localVarFormParams.set('sender', sender as any)
      }

      if (strippedText !== undefined) {
        localVarFormParams.set('stripped-text', strippedText as any)
      }

      if (timestamp !== undefined) {
        localVarFormParams.set('timestamp', timestamp as any)
      }

      if (token !== undefined) {
        localVarFormParams.set('token', token as any)
      }

      if (signature !== undefined) {
        localVarFormParams.set('signature', signature as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Triggered when plain SMS changed its status
     * @summary plain sms status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandlePlainSmsTwilioStatusWebhook(
      messageId: string,
      body: WebHookTwilioStatusDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'messageId' is not null or undefined
      if (messageId === null || messageId === undefined) {
        throw new RequiredError(
          'messageId',
          'Required parameter messageId was null or undefined when calling webHookControllerHandlePlainSmsTwilioStatusWebhook.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling webHookControllerHandlePlainSmsTwilioStatusWebhook.'
        )
      }
      const localVarPath = `/webhook/twilio/plain-sms-status`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (messageId !== undefined) {
        localVarQueryParameter['messageId'] = messageId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WebHookTwilioStatusDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Triggered when Twilio can't process a call
     * @summary fatal error webhook
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioFallbackWebhook(channelId: string, options: any = {}): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling webHookControllerHandleTwilioFallbackWebhook.'
        )
      }
      const localVarPath = `/webhook/twilio/fallback`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (channelId !== undefined) {
        localVarQueryParameter['channelId'] = channelId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Triggered when SMS changed its status
     * @summary status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioStatusWebhook(
      messageId: string,
      body: WebHookTwilioStatusDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'messageId' is not null or undefined
      if (messageId === null || messageId === undefined) {
        throw new RequiredError(
          'messageId',
          'Required parameter messageId was null or undefined when calling webHookControllerHandleTwilioStatusWebhook.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling webHookControllerHandleTwilioStatusWebhook.'
        )
      }
      const localVarPath = `/webhook/twilio/status`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (messageId !== undefined) {
        localVarQueryParameter['messageId'] = messageId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WebHookTwilioStatusDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Triggered by incoming SMS
     * @summary incoming message webhook
     * @param {string} channelId
     * @param {WebHookTwilioSuccessDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioSuccessWebhook(
      channelId: string,
      body: WebHookTwilioSuccessDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'channelId' is not null or undefined
      if (channelId === null || channelId === undefined) {
        throw new RequiredError(
          'channelId',
          'Required parameter channelId was null or undefined when calling webHookControllerHandleTwilioSuccessWebhook.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling webHookControllerHandleTwilioSuccessWebhook.'
        )
      }
      const localVarPath = `/webhook/twilio/sms`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (channelId !== undefined) {
        localVarQueryParameter['channelId'] = channelId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WebHookTwilioSuccessDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * WebhookControllerApi - functional programming interface
 * @export
 */
export const WebhookControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Forward email text to user's chat
     * @summary webhook for mailgun
     * @param {string} recipient
     * @param {string} sender
     * @param {string} strippedText
     * @param {string} timestamp
     * @param {string} token
     * @param {string} signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleMailgunWebhook(
      recipient: string,
      sender: string,
      strippedText: string,
      timestamp: string,
      token: string,
      signature: string,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WebhookControllerApiFetchParamCreator(
        configuration
      ).webHookControllerHandleMailgunWebhook(recipient, sender, strippedText, timestamp, token, signature, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Triggered when plain SMS changed its status
     * @summary plain sms status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandlePlainSmsTwilioStatusWebhook(
      messageId: string,
      body: WebHookTwilioStatusDTO,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WebhookControllerApiFetchParamCreator(
        configuration
      ).webHookControllerHandlePlainSmsTwilioStatusWebhook(messageId, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Triggered when Twilio can't process a call
     * @summary fatal error webhook
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioFallbackWebhook(
      channelId: string,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WebhookControllerApiFetchParamCreator(
        configuration
      ).webHookControllerHandleTwilioFallbackWebhook(channelId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Triggered when SMS changed its status
     * @summary status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioStatusWebhook(
      messageId: string,
      body: WebHookTwilioStatusDTO,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WebhookControllerApiFetchParamCreator(
        configuration
      ).webHookControllerHandleTwilioStatusWebhook(messageId, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     * Triggered by incoming SMS
     * @summary incoming message webhook
     * @param {string} channelId
     * @param {WebHookTwilioSuccessDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioSuccessWebhook(
      channelId: string,
      body: WebHookTwilioSuccessDTO,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WebhookControllerApiFetchParamCreator(
        configuration
      ).webHookControllerHandleTwilioSuccessWebhook(channelId, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * WebhookControllerApi - factory interface
 * @export
 */
export const WebhookControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Forward email text to user's chat
     * @summary webhook for mailgun
     * @param {string} recipient
     * @param {string} sender
     * @param {string} strippedText
     * @param {string} timestamp
     * @param {string} token
     * @param {string} signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleMailgunWebhook(
      recipient: string,
      sender: string,
      strippedText: string,
      timestamp: string,
      token: string,
      signature: string,
      options?: any
    ) {
      return WebhookControllerApiFp(configuration).webHookControllerHandleMailgunWebhook(
        recipient,
        sender,
        strippedText,
        timestamp,
        token,
        signature,
        options
      )(basePath)
    },
    /**
     * Triggered when plain SMS changed its status
     * @summary plain sms status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandlePlainSmsTwilioStatusWebhook(messageId: string, body: WebHookTwilioStatusDTO, options?: any) {
      return WebhookControllerApiFp(configuration).webHookControllerHandlePlainSmsTwilioStatusWebhook(
        messageId,
        body,
        options
      )(basePath)
    },
    /**
     * Triggered when Twilio can't process a call
     * @summary fatal error webhook
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioFallbackWebhook(channelId: string, options?: any) {
      return WebhookControllerApiFp(configuration).webHookControllerHandleTwilioFallbackWebhook(channelId, options)(
        basePath
      )
    },
    /**
     * Triggered when SMS changed its status
     * @summary status change webhook
     * @param {string} messageId
     * @param {WebHookTwilioStatusDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioStatusWebhook(messageId: string, body: WebHookTwilioStatusDTO, options?: any) {
      return WebhookControllerApiFp(configuration).webHookControllerHandleTwilioStatusWebhook(messageId, body, options)(
        basePath
      )
    },
    /**
     * Triggered by incoming SMS
     * @summary incoming message webhook
     * @param {string} channelId
     * @param {WebHookTwilioSuccessDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webHookControllerHandleTwilioSuccessWebhook(channelId: string, body: WebHookTwilioSuccessDTO, options?: any) {
      return WebhookControllerApiFp(configuration).webHookControllerHandleTwilioSuccessWebhook(
        channelId,
        body,
        options
      )(basePath)
    }
  }
}

/**
 * WebhookControllerApi - object-oriented interface
 * @export
 * @class WebhookControllerApi
 * @extends {BaseAPI}
 */
export class WebhookControllerApi extends BaseAPI {
  /**
   * Forward email text to user's chat
   * @summary webhook for mailgun
   * @param {string} recipient
   * @param {string} sender
   * @param {string} strippedText
   * @param {string} timestamp
   * @param {string} token
   * @param {string} signature
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public webHookControllerHandleMailgunWebhook(
    recipient: string,
    sender: string,
    strippedText: string,
    timestamp: string,
    token: string,
    signature: string,
    options?: any
  ) {
    return WebhookControllerApiFp(this.configuration).webHookControllerHandleMailgunWebhook(
      recipient,
      sender,
      strippedText,
      timestamp,
      token,
      signature,
      options
    )(this.basePath)
  }

  /**
   * Triggered when plain SMS changed its status
   * @summary plain sms status change webhook
   * @param {string} messageId
   * @param {WebHookTwilioStatusDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public webHookControllerHandlePlainSmsTwilioStatusWebhook(
    messageId: string,
    body: WebHookTwilioStatusDTO,
    options?: any
  ) {
    return WebhookControllerApiFp(this.configuration).webHookControllerHandlePlainSmsTwilioStatusWebhook(
      messageId,
      body,
      options
    )(this.basePath)
  }

  /**
   * Triggered when Twilio can't process a call
   * @summary fatal error webhook
   * @param {string} channelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public webHookControllerHandleTwilioFallbackWebhook(channelId: string, options?: any) {
    return WebhookControllerApiFp(this.configuration).webHookControllerHandleTwilioFallbackWebhook(channelId, options)(
      this.basePath
    )
  }

  /**
   * Triggered when SMS changed its status
   * @summary status change webhook
   * @param {string} messageId
   * @param {WebHookTwilioStatusDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public webHookControllerHandleTwilioStatusWebhook(messageId: string, body: WebHookTwilioStatusDTO, options?: any) {
    return WebhookControllerApiFp(this.configuration).webHookControllerHandleTwilioStatusWebhook(
      messageId,
      body,
      options
    )(this.basePath)
  }

  /**
   * Triggered by incoming SMS
   * @summary incoming message webhook
   * @param {string} channelId
   * @param {WebHookTwilioSuccessDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public webHookControllerHandleTwilioSuccessWebhook(channelId: string, body: WebHookTwilioSuccessDTO, options?: any) {
    return WebhookControllerApiFp(this.configuration).webHookControllerHandleTwilioSuccessWebhook(
      channelId,
      body,
      options
    )(this.basePath)
  }
}

/**
 * WorkOrderControllerApi - fetch parameter creator
 * @export
 */
export const WorkOrderControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a message with type work order and send SMS when user.mobileApp=false
     * @param {WorkOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerCreate(body: WorkOrderDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling workOrderControllerCreate.'
        )
      }
      const localVarPath = `/work-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WorkOrderDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary generate a HTML-page with Work Order
     * @param {string} dispatchDeliveryOrderId
     * @param {string} driverId
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerGenerateWorkOrderPage(
      dispatchDeliveryOrderId: string,
      driverId: string,
      hash: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling workOrderControllerGenerateWorkOrderPage.'
        )
      }
      // verify required parameter 'driverId' is not null or undefined
      if (driverId === null || driverId === undefined) {
        throw new RequiredError(
          'driverId',
          'Required parameter driverId was null or undefined when calling workOrderControllerGenerateWorkOrderPage.'
        )
      }
      // verify required parameter 'hash' is not null or undefined
      if (hash === null || hash === undefined) {
        throw new RequiredError(
          'hash',
          'Required parameter hash was null or undefined when calling workOrderControllerGenerateWorkOrderPage.'
        )
      }
      const localVarPath = `/work-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (dispatchDeliveryOrderId !== undefined) {
        localVarQueryParameter['dispatchDeliveryOrderId'] = dispatchDeliveryOrderId
      }

      if (driverId !== undefined) {
        localVarQueryParameter['driverId'] = driverId
      }

      if (hash !== undefined) {
        localVarQueryParameter['hash'] = hash
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * WorkOrderControllerApi - functional programming interface
 * @export
 */
export const WorkOrderControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a message with type work order and send SMS when user.mobileApp=false
     * @param {WorkOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerCreate(body: WorkOrderDTO, options?: any): (basePath?: string) => Promise<Array<MessageDTO>> {
      const localVarFetchArgs = WorkOrderControllerApiFetchParamCreator(configuration).workOrderControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary generate a HTML-page with Work Order
     * @param {string} dispatchDeliveryOrderId
     * @param {string} driverId
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerGenerateWorkOrderPage(
      dispatchDeliveryOrderId: string,
      driverId: string,
      hash: string,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = WorkOrderControllerApiFetchParamCreator(
        configuration
      ).workOrderControllerGenerateWorkOrderPage(dispatchDeliveryOrderId, driverId, hash, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * WorkOrderControllerApi - factory interface
 * @export
 */
export const WorkOrderControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a message with type work order and send SMS when user.mobileApp=false
     * @param {WorkOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerCreate(body: WorkOrderDTO, options?: any) {
      return WorkOrderControllerApiFp(configuration).workOrderControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary generate a HTML-page with Work Order
     * @param {string} dispatchDeliveryOrderId
     * @param {string} driverId
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderControllerGenerateWorkOrderPage(
      dispatchDeliveryOrderId: string,
      driverId: string,
      hash: string,
      options?: any
    ) {
      return WorkOrderControllerApiFp(configuration).workOrderControllerGenerateWorkOrderPage(
        dispatchDeliveryOrderId,
        driverId,
        hash,
        options
      )(basePath)
    }
  }
}

/**
 * WorkOrderControllerApi - object-oriented interface
 * @export
 * @class WorkOrderControllerApi
 * @extends {BaseAPI}
 */
export class WorkOrderControllerApi extends BaseAPI {
  /**
   *
   * @summary create a message with type work order and send SMS when user.mobileApp=false
   * @param {WorkOrderDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderControllerApi
   */
  public workOrderControllerCreate(body: WorkOrderDTO, options?: any) {
    return WorkOrderControllerApiFp(this.configuration).workOrderControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary generate a HTML-page with Work Order
   * @param {string} dispatchDeliveryOrderId
   * @param {string} driverId
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderControllerApi
   */
  public workOrderControllerGenerateWorkOrderPage(
    dispatchDeliveryOrderId: string,
    driverId: string,
    hash: string,
    options?: any
  ) {
    return WorkOrderControllerApiFp(this.configuration).workOrderControllerGenerateWorkOrderPage(
      dispatchDeliveryOrderId,
      driverId,
      hash,
      options
    )(this.basePath)
  }
}

export type DateISOString = string // 2018-04-15T08:36:01.711Z
