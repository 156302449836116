export const getMappingById = <T>(list: T[]): Record<string, T> => {
  try {
    return (list || []).reduce((acc, curr) => {
      // @ts-ignore
      if (curr && curr.id) {
        // @ts-ignore
        acc[curr.id] = curr
      }

      return acc
    }, {})
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
    return {}
  }
}
