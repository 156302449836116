import * as R from 'remeda'
import { callAPI, DailyCountDTO, DateTimeRangeDTO, OngoingActivityGroupDTO, schedulerAPI } from '../../../../api/api'
import { dateService } from '../../../../services/timeService'
import moveServiceAPI, { handleMoveServiceError } from '../../../CommunicationHub/epics/moveServiceAxios'
import { createFilterRequest } from '../../../../services/uiSettingsService/filter'
import { requestDispatchDeliveryOrdersByIds } from '../../dispatchDeliveryOrder/epics'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { IMoveDTO } from '../../../../store/reducers/communicationHub/interfaces'
import { ColumnDTO } from '../../../../store/reducers/tabs/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { getListsState } from '../../../../store'
import { isFullObject } from '../../../../services/functions/test/isFullObject'
import { requestChatChannelsCounters } from '../../../CommunicationHub/epics'

export const getSchedulerKeyPerformanceIndicator = () => {
  return callAPI(schedulerAPI.getKeyPerformanceIndicator, new Date().getTimezoneOffset()).toPromise()
}

export const getSchedulerDailyLfdCutOffCounts = (date: string): Promise<DailyCountDTO> => {
  return callAPI(schedulerAPI.getDailyCounts, date)
    .toPromise()
    .catch(() => ({
      cutoffCount: 0,
      lfdCount: 0
    }))
}

export const getOngoingActivities = (props: { filter: string; sort: string }): Promise<OngoingActivityGroupDTO[]> => {
  return callAPI(schedulerAPI.findAll, props.filter, props.sort)
    .toPromise()
    .then(async ongoingActivities => {
      if (props.filter.includes(OngoingActivityGroupDTO.GroupingEnum.STAGE.toString())) {
        const ongoingActivityDateStage: OngoingActivityGroupDTO[] = []
        const ongoingActivityDateStageNow: OngoingActivityGroupDTO[] = []

        ongoingActivities.forEach(ongoingActivity => {
          if (ongoingActivity.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE) {
            ongoingActivityDateStage.push(ongoingActivity)
          } else if (ongoingActivity.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGENOW) {
            ongoingActivityDateStageNow.push(ongoingActivity)
          }
        })
        await pushListItemsToStore({
          update: {
            [EntityType.ongoingActivityDateStage]: ongoingActivityDateStage,
            [EntityType.ongoingActivityDateStageNow]: ongoingActivityDateStageNow
          }
        })
      } else if (props.filter.includes(OngoingActivityGroupDTO.GroupingEnum.DRIVER.toString())) {
        await pushListItemsToStore({
          update: {
            [EntityType.ongoingActivityDateDriver]: ongoingActivities
          }
        })
      }

      if (ongoingActivities && ongoingActivities.length) {
        await requestChatChannelsCounters(ongoingActivities.map(item => item.dispatchDeliveryOrderId))
      }

      return ongoingActivities
    })
}

// mm-dd-yyyy
export const getListOfUsedIdsOnSpecificDate = (date: string): Promise<string[]> => {
  // const store = getStore().getState()
  // const driverIds: string[] = R.uniq(Object.values(store.driver).map(driver => driver.id))
  // const randomCount = [0, 7, 2, 5, 10, 15]
  // const getRrandArrayItem = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)]
  // const count = getRrandArrayItem(randomCount)
  // const resultIds: string[] = []
  // let i = 0

  // while (i < count) {
  //   resultIds.push(getRrandArrayItem(driverIds))
  //   i++
  // }

  // return Promise.resolve(resultIds)

  return callAPI(schedulerAPI.findVendorsAssignedOnDeliveryStage, dateService.getStringDay(date)).toPromise()
}

export const getMoves = async (props?: { vendorId?: string; plannedDate?: DateTimeRangeDTO }) => {
  const { vendorId, plannedDate } = props || {}
  const filterParams: ColumnDTO.Filter[] = [
    { column: 'vendorId', value: vendorId },
    { column: 'plannedDate', value: plannedDate }
  ].filter(f => f.value)
  const filter = createFilterRequest(filterParams)

  return callAPI(() => moveServiceAPI.get(`/grid-move`, filter ? { params: { filter } } : undefined))
    .toPromise()
    .then(async data => {
      if (data && typeof data.data === 'object') {
        const moves = data.data as IMoveDTO[]

        if (moves.length) {
          await pushListItemsToStore({ update: { [EntityType.move]: moves } })
        }

        // remove already existed Dispatch Delivery Orders from the request
        const storeLists = getListsState()
        const dispatchDeliveryOrderMapping = storeLists.dispatchDeliveryOrder
        let dispatchDeliveryOrderIds: string[] = []

        moves.forEach(move => {
          const { dispatchDeliveryOrderId } = move
          const dispatchDeliveryOrder = dispatchDeliveryOrderMapping[dispatchDeliveryOrderId]

          if (!isFullObject(dispatchDeliveryOrder)) {
            dispatchDeliveryOrderIds.push(dispatchDeliveryOrderId)
          }
        })

        dispatchDeliveryOrderIds = R.uniq(dispatchDeliveryOrderIds)

        // get needed Dispatch Delivery Orders & activities
        await requestDispatchDeliveryOrdersByIds(dispatchDeliveryOrderIds, { notFullObjectWithActivities: true })
        if (dispatchDeliveryOrderIds && dispatchDeliveryOrderIds.length) {
          await requestChatChannelsCounters(dispatchDeliveryOrderIds)
        }

        return moves
      } else {
        return []
      }
    })
    .catch(handleMoveServiceError([]))
}
