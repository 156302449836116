import { GeneralCounterDTO } from '../../../api/origin/communication-hub-service'
import { IChannelMeta } from '../../../store/reducers/communicationHub/interfaces'

export const generateChannelMeta = (props?: Partial<IChannelMeta> & { id: string }): IChannelMeta => {
  return {
    id: props.id,
    type: GeneralCounterDTO.TypeEnum.DDO,
    count: 0,
    mute: false,
    mentionMsgIds: [],
    pinnedMsgIds: [],
    alertMsgIds: [],
    updatedAt: null,
    ...(props || {})
  }
}
